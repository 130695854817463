import { Box, DialogContent, Grid, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/textInput";
import DialogFooter from "./../../../../components/dialog/dialogFooter";
import DialogHeader from "./../../../../components/dialog/dialogHeader";
import BootstrapDialog from "./../../../../components/dialog/index";
import SnackbarAlert from "../../../../components/snackbar";
import CustomInputLabel from "../../../../components/customInputLabel";
import CustomDropDown from "../../../../components/customDropDown";
import {
  createParticipants,
  updateParticipants,
} from "../../../../services/participants";
import { useSelector } from "react-redux";
function AddEditParticipants({
  type,
  open,
  handleClose,
  dialogData,
  fetchData,
  onclosePress,
  setIsLoading,
  companyId,
}) {
  const { userData } = useSelector((state) => state.Authentication);
  const [inputVal, setInputVal] = useState({
    name: "",
    position: "",
    openMeeting: "",
    closeMeeting: "",
  });

  useEffect(() => {
    if (type === "edit" && dialogData) {
      setInputVal({
        name: dialogData?.participant_name,
        position: dialogData?.participant_position,
        openMeeting: dialogData?.opening_meeting == "Y" ? "Yes" : "No",
        closeMeeting: dialogData?.closing_meeting == "Y" ? "Yes" : "No",
      });
    } else {
      setInputVal({
        name: "",
        position: "",
        openMeeting: "",
        closeMeeting: "",
      });
    }
  }, [type, dialogData]);

  const handleChange = (e, name) => {
    setInputVal((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleSave = async () => {
    if (inputVal.name == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter participant name",
      });
    } else if (inputVal.name == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter participant position",
      });
    } else if (inputVal.openMeeting == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please select open meeting option",
      });
    } else if (inputVal.closeMeeting == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please select close meeting option",
      });
    } else {
      setIsLoading(true);
      let params = {
        ...(type === "add" && { company_audit_id: companyId }),
        participant_name: inputVal.name,
        participant_position: inputVal.position,
        opening_meeting: inputVal.openMeeting == "Yes" ? "Y" : "N",
        closing_meeting: inputVal.closeMeeting == "Yes" ? "Y" : "N",
      };
      try {
        if (type === "add") {
          await createParticipants(params, userData.token);
          fetchData();
          snackRef.current.isOpenSnack({
            snackType: "success",
            snackMessage: "Participant added successfully",
          });
        } else {
          await updateParticipants(
            { ...params, id: dialogData.id },
            userData.token
          );
          fetchData();
          snackRef.current.isOpenSnack({
            snackType: "success",
            snackMessage: "Participant updated successfully",
          });
        }
      } catch (err) {
        console.error("Error saving company:", err);
      } finally {
        setIsLoading(false);
        handleClose();
      }
    }
  };

  const snackRef = useRef();

  return (
    <Box>
      <SnackbarAlert ref={snackRef} />
      <BootstrapDialog
        onclosePress={onclosePress}
        onClose={onclosePress}
        open={open}
      >
        <DialogHeader
          title={type === "add" ? "Add Participant" : "Edit Participant"}
          handleClose={onclosePress}
        />
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <CustomInputLabel label="Name" />
              <TextInput
                placeholder="Name"
                value={inputVal.name}
                onChange={(e) => handleChange(e, "name")}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel label="Position" />
              <TextInput
                placeholder="Position"
                value={inputVal.position}
                onChange={(e) => handleChange(e, "position")}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomDropDown
                fieldLabel={"Opening Meeting?"}
                handleChange={handleChange}
                fieldName={"openMeeting"}
                selectedValue={inputVal.openMeeting}
              >
                <MenuItem value={""}>Select Option</MenuItem>
                {["Yes", "No"].map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </CustomDropDown>
            </Grid>
            <Grid item xs={12}>
              <CustomDropDown
                fieldLabel={"Closing Meeting?"}
                handleChange={handleChange}
                fieldName={"closeMeeting"}
                selectedValue={inputVal.closeMeeting}
              >
                <MenuItem value={""}>Select Option</MenuItem>
                {["Yes", "No"].map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </CustomDropDown>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleSave}
          handleUpdate={handleSave}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </Box>
  );
}

export default AddEditParticipants;

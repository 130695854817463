import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";

const tableData1 = [
  {
    id: 1,
    openAgenda:
      "Confirmation of confidentiality Yes Confirmation of confidentiality",
    value: "Yes",
    closeAgenda:
      "Confirmation of confidentiality Yes Confirmation of confidentiality",
    closeValue: "Yes",
  },
  {
    id: 2,
    openAgenda:
      "Introduction of the auditor/team including an outline of their roles",
    value: "Yes",
    closeAgenda:
      "Explain the method and timeframe of reporting, including any grading of audit findings",
    closeValue: "Yes",
  },
  {
    id: 3,
    openAgenda:
      "Confirmation of the audit plan (including type and scope of audit, objectives and criteria), any changes, and other relevant arrangements with the client, such as the date and time for the closing meeting, interim meetings between the audit team and the client’s management",
    value: "Yes",
    closeAgenda:
      "Advise the client that the audit evidence obtained was based on a sample of the information; thereby introducing an element of uncertainty",
    closeValue: "Yes",
  },
  {
    id: 4,
    openAgenda: "Confirmation on the scope of certification",
    value: "Yes",
    closeAgenda:
      "Explain the ForeFront post audit activities; e.g. audit report technical review",
    closeValue: "Yes",
  },
  {
    id: 5,
    openAgenda:
      "Confirmation of formal communication channels between the audit team and the client",
    value: "Yes",
    closeAgenda:
      "The ForeFront process for handling nonconformities, including any consequences relating to the status of the client’s certification",
    closeValue: "Yes",
  },
  {
    id: 6,
    openAgenda:
      "Confirmation that resources and facilities needed by the audit team are available",
    value: "Yes",
    closeAgenda:
      "The timeframe for the client to present a plan for correction and corrective action for any nonconformities identified during the audit",
    closeValue: "Yes",
  },
  {
    id: 7,
    openAgenda:
      "Confirmation of relevant work safety, emergency and security procedures for the audit team",
    value: "Yes",
    closeAgenda:
      "Information about the complaint and appeal handling processes",
    closeValue: "Yes",
  },
  {
    id: 8,
    openAgenda:
      "Confirm Impartiality: The audit team and client confirm there are no associated conflicts of interest",
    value: "Yes",
    closeAgenda:
      "Confirm Impartiality: The audit team and client confirm there are no associated conflicts of interest",
    closeValue: "Yes",
  },
  {
    id: 9,
    openAgenda:
      "Confirmation of the availability, roles and identities of any guides, observers, technical experts as applicable (Note, a technical expert shall not act as an auditor in the audit team)",
    value: "Yes",
    closeAgenda:
      "Confirm the appropriateness of the audit programme and identify any modification required for future audits (e.g. scope of certification, audit time or dates, audit frequency)",
    closeValue: "Yes",
  },
  {
    id: 10,
    openAgenda:
      "Explain method of audit reporting and explain the findings i.e.; Major Non-Conformity – total system failure Minor Non-Conformity – Single lapse OFI – Opportunity for improvement",
    value: "Yes",
    closeAgenda: "Opportunity for the client to ask questions",
    closeValue: "Yes",
  },
  {
    id: 11,
    openAgenda:
      "Inform the client about the conditions under which the audit may be prematurely terminated – e.g. Health and Safety Concern, if the auditor identifies multiple situations of non-conformity, system in decline, system not ready for stage 2, situations of statutory and regulatory",
    value: "Yes",
    closeAgenda: "",
    closeValue: "",
  },
  {
    id: 12,
    openAgenda:
      "Confirmation that the audit team leader and audit team representing the certification body is responsible for the audit and shall be in control of executing the audit plan including audit activities and audit trails",
    value: "Yes",
    closeAgenda: "",
    closeValue: "",
  },
  {
    id: 13,
    openAgenda:
      "Confirmation of the status of findings of the previous review or audit, if applicable",
    value: "Yes",
    closeAgenda: "",
    closeValue: "",
  },
  {
    id: 14,
    openAgenda:
      "Methods and procedures to be used to conduct the audit based on sampling",
    value: "Yes",
    closeAgenda: "",
    closeValue: "",
  },
  {
    id: 15,
    openAgenda: "Confirmation of the language to be used during the audit",
    value: "Yes",
    closeAgenda: "",
    closeValue: "",
  },
  {
    id: 16,
    openAgenda:
      "Confirmation that, during the audit, the client will be kept informed of audit progress and any concerns",
    value: "Yes",
    closeAgenda: "",
    closeValue: "",
  },
  {
    id: 17,
    openAgenda: "Opportunity for the client to ask questions",
    value: "Yes",
    closeAgenda: "",
    closeValue: "",
  },
];

const PageThirtyFive = () => {
  const renderSameField = (
    fieldd,
    valueeee,
    secondField,
    secondValue,
    indexxx,
    arrLength
  ) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottom: arrLength - 1 !== indexxx ? "1px solid black" : "",
        }}
      >
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "2px",
            paddingLeft: "5px",
            width: "90%",
            flex: 1,
            height: "100%",
          }}
        >
          {fieldd}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "2px",
            textAlign: "center",
            height: "100%",
            width: "12%",
          }}
        >
          {valueeee}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "2px",
            paddingLeft: "5px",
            width: "90%",
            flex: 1,
            height: "100%",
          }}
        >
          {secondField}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            paddingTop: "2px",
            textAlign: "center",
            height: "100%",
            width: "12%",
          }}
        >
          {secondValue}
        </Text>
      </View>
    );
  };

  return (
    <Page size={"A4"}>
      <PDFHeaderFooter>
        <View style={{ width: "90%", alignSelf: "center" }}>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: "700",
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "4px",
              textDecoration: "underline",
            }}
          >
            Appendix A - Opening and Closing meeting agenda
          </Text>
        </View>

        <View
          style={{
            border: "1px solid black",
            width: "90%",
            alignSelf: "center",
            marginTop: "6px",
          }}
        >
          {renderSameField(
            "Opening Meeting Agenda",
            "Yes/No",
            "Closing Meeting Agenda",
            "Yes/No"
          )}
          {tableData1.map((itemmm, indexxx) => {
            return renderSameField(
              itemmm.openAgenda,
              itemmm.value,
              itemmm.closeAgenda,
              itemmm.closeValue,
              indexxx,
              tableData1.length
            );
          })}
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default PageThirtyFive;

import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";

const tableData1 = [
  {
    id: 1,
    name: "Opening meeting",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 2,
    name: "Review of any changes",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 3,
    name: "Actions taken on findings identified during the previous audits",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 4,
    name: "Use of marks and/or any other reference to certification.",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 5,
    name: "Use of marks and/or any other reference to certification.",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 6,
    name: "Scope",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 7,
    name: "Legal and statutory requirements",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 8,
    name: "Context changes, risks and opportunities",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 9,
    name: "Objectives and measures",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 10,
    name: "Management review",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 11,
    name: "Continual Improvement",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 12,
    name: "Internal audits, nonconformity and corrective action",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 13,
    name: "Customer satisfaction",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 14,
    name: "Accidents/Incidents and reporting",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
  {
    id: 15,
    name: "Complaints (Customers, other)",
    sv1: "X",
    sv2: "X",
    sv3: "X",
  },
];
const tableData2 = [
  {
    id: 1,
    name: "Site tour / Work environment",
    survellience1: "X",
    survellience2: "X",
    survellience3: "X",
  },
  {
    id: 2,
    name: "Policies",
    survellience1: "",
    survellience2: "X",
    survellience3: "",
  },
  {
    id: 3,
    name: "Leadership interview",
    survellience1: "",
    survellience2: "",
    survellience3: "X",
  },
  {
    id: 4,
    name: "Document and record control",
    survellience1: "X",
    survellience2: "X",
    survellience3: "X",
  },
  {
    id: 5,
    name: "Resources, roles, responsibilities and authorities",
    survellience1: "",
    survellience2: "",
    survellience3: "X",
  },
  {
    id: 6,
    name: "Training, Competence and organisational knowledge",
    survellience1: "",
    survellience2: "",
    survellience3: "X",
  },
  {
    id: 7,
    name: "Awareness and communication",
    survellience1: "X",
    survellience2: "X",
    survellience3: "X",
  },
  {
    id: 8,
    name: "Sales order processing",
    survellience1: "X",
    survellience2: "",
    survellience3: "X",
  },
  {
    id: 9,
    name: "Purchasing, supplier controls",
    survellience1: "X",
    survellience2: "",
    survellience3: "",
  },
  {
    id: 10,
    name: "Site visit: Facilities Management",
    survellience1: "X",
    survellience2: "",
    survellience3: "X",
  },
  {
    id: 11,
    name: "Site visit: Security Guarding",
    survellience1: "X",
    survellience2: "",
    survellience3: "X",
  },
  {
    id: 12,
    name: "Maintenance / Infrastructure",
    survellience1: "X",
    survellience2: "",
    survellience3: "X",
  },
  {
    id: 13,
    name: "Environmental Aspects",
    survellience1: "X",
    survellience2: "X",
    survellience3: "X",
  },
  {
    id: 14,
    name: "Waste Management",
    survellience1: "X",
    survellience2: "X",
    survellience3: "X",
  },
  {
    id: 15,
    name: "COSHH",
    survellience1: "",
    survellience2: "X",
    survellience3: "",
  },
  {
    id: 16,
    name: "Emergency Preparedness and response",
    survellience1: "",
    survellience2: "X",
    survellience3: "",
  },
  {
    id: 17,
    name: "Legal compliance and evaluation of compliance",
    survellience1: "",
    survellience2: "X",
    survellience3: "",
  },
  {
    id: 18,
    name: "Hazard identification and risk assessments",
    survellience1: "",
    survellience2: "X",
    survellience3: "",
  },
  {
    id: 19,
    name: "Consultation and participation",
    survellience1: "X",
    survellience2: "",
    survellience3: "X",
  },
  {
    id: 20,
    name: "Recertification",
    survellience1: "",
    survellience2: "",
    survellience3: "X",
  },
];

const PagethirtyOne = ({ data }) => {
  let myData = data[0];

  const renderSameField = (itemmmm, indexxx, arrLength, bgColor, width) => {
    return (
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <Text
          style={{
            fontSize: "8px",
            fontWeight: "900",
            width: width,
            paddingLeft: "10px",
            paddingVertical: "3px",
            borderBottom:
              arrLength - 1 !== indexxx ? "1px solid black" : "none",
            borderRight: "1px solid black",
            backgroundColor: bgColor ? "#f2f2f2" : "transparent",
          }}
        >
          {itemmmm.name}
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Text
            style={{
              fontSize: "5px",
              fontWeight: "900",
              flex: 1,
              height: "100%",
              textAlign: "center",
              paddingVertical: "3px",
              borderBottom:
                arrLength - 1 !== indexxx ? "1px solid black" : "none",
              borderRight: "1px solid black",
              backgroundColor: bgColor ? "#f2f2f2" : "transparent",
            }}
          >
            {itemmmm.sv1 ? "x" : ""}
          </Text>
          <Text
            style={{
              fontSize: "5px",
              fontWeight: "900",
              flex: 1,
              height: "100%",
              textAlign: "center",
              paddingVertical: "3px",
              borderBottom:
                arrLength - 1 !== indexxx ? "1px solid black" : "none",
              borderRight: "1px solid black",
              backgroundColor: bgColor ? "#f2f2f2" : "transparent",
            }}
          >
            {itemmmm.sv2 ? "x" : ""}
          </Text>
          <Text
            style={{
              fontSize: "5px",
              fontWeight: "900",
              flex: 1,
              height: "100%",
              textAlign: "center",
              paddingVertical: "3px",
              borderBottom:
                arrLength - 1 !== indexxx ? "1px solid black" : "none",
              backgroundColor: bgColor ? "#f2f2f2" : "transparent",
            }}
          >
            {itemmmm.sv3 ? "x" : ""}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <Page size={"A4"}>
      <PDFHeaderFooter>
        <Text style={{ marginVertical: "10px", fontSize: "14px" }}>
          3 Year audit programme plan of process areas:
        </Text>
        <View
          style={{
            border: "1px solid black",
            width: "90%",
            alignSelf: "center",
          }}
        >
          <View
            style={{ flexDirection: "row", borderBottom: "1px solid black" }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "60%",
                borderRight: "1px solid black",
              }}
            >
              <Text
                style={{
                  fontSize: "8px",
                  fontWeight: "900",
                  paddingLeft: "10px",
                  paddingVertical: "3px",
                }}
              >
                Business Processes
              </Text>
            </View>
            <View style={{ flexDirection: "row", width: "40%" }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "8px",
                    fontWeight: "900",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                    padding: "4px",
                  }}
                >
                  Surveillance 1 Feb 2024
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "4px",
                  }}
                >
                  IMS 3 days
                </Text>
              </View>
              <View
                style={{
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: "8px",
                    fontWeight: "900",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                    padding: "4px",
                  }}
                >
                  Surveillance 1 Feb 2024
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "4px",
                  }}
                >
                  IMS 3 days
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "8px",
                    fontWeight: "900",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                    padding: "4px",
                  }}
                >
                  Surveillance 1 Feb 2024
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "4px",
                  }}
                >
                  IMS 3 days
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottom: "1px solid black",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "900",
                width: "10%",
                borderRight: "1px solid black",
                paddingTop: "10px",
                textAlign: "center",
                lineHeight: "1.5px",
              }}
            >
              Mandatory and must be conducted at every audit
            </Text>

            <View style={{ flex: 1 }}>
              {tableData1.map((itemmmm, index) => {
                return renderSameField(
                  itemmmm,
                  index,
                  tableData1.length,
                  false,
                  "55.5%"
                );
              })}
            </View>
          </View>

          {myData.next_years_plan.map((itemmmm, index) => {
            return renderSameField(
              itemmmm,
              index,
              tableData2.length,
              true,
              "59.95%"
            );
          })}

          <View
            style={{
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "900",
                width: "59.9%",
                paddingLeft: "10px",
                paddingVertical: "3px",
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                backgroundColor: "#f2f2f2",
              }}
            >
              ISO 45001 only: Specific interviews programmed over the cycle for:
              <Text style={{ fontWeight: "400" }}>
                • Employee representatives(s) with responsibility for OH&S, •
                Personnel responsible for monitoring employees' health, •
                Managers and permanent and temporary employees • Managers and
                employees performing activities related to the prevention of
                OH&S Safety risks • Contractors’ management and employees.
              </Text>
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: "5px",
                  fontWeight: "900",
                  flex: 1,
                  height: "100%",
                  textAlign: "center",
                  paddingVertical: "3px",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  backgroundColor: "#f2f2f2",
                }}
              >
                X
              </Text>
              <Text
                style={{
                  fontSize: "5px",
                  fontWeight: "900",
                  flex: 1,
                  height: "100%",
                  textAlign: "center",
                  paddingVertical: "3px",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  backgroundColor: "#f2f2f2",
                }}
              >
                X
              </Text>
              <Text
                style={{
                  fontSize: "5px",
                  fontWeight: "900",
                  flex: 1,
                  height: "100%",
                  textAlign: "center",
                  paddingVertical: "3px",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  backgroundColor: "#f2f2f2",
                }}
              >
                X
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "400",
                width: "59.9%",
                paddingLeft: "10px",
                paddingVertical: "3px",
                borderRight: "1px solid black",
              }}
            >
              Closing meeting
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: "5px",
                  fontWeight: "900",
                  flex: 1,
                  height: "100%",
                  textAlign: "center",
                  paddingVertical: "3px",
                  borderRight: "1px solid black",
                }}
              >
                X
              </Text>
              <Text
                style={{
                  fontSize: "5px",
                  fontWeight: "900",
                  flex: 1,
                  height: "100%",
                  textAlign: "center",
                  paddingVertical: "3px",
                  borderRight: "1px solid black",
                }}
              >
                X
              </Text>
              <Text
                style={{
                  fontSize: "5px",
                  fontWeight: "900",
                  flex: 1,
                  height: "100%",
                  textAlign: "center",
                  paddingVertical: "3px",
                }}
              >
                X
              </Text>
            </View>
          </View>
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default PagethirtyOne;

import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import CustomButton from "../../../../components/customButton";
import AddEditISOStandard from "./AddEditISOStandard";
import { getAllStandards, updateIsoStandardStatus } from "../../../../services/isoStandards";
import SnackbarAlert from "../../../../components/snackbar";
import DescriptionDialog from "./DescriptionDialog";

const headerData = [
    { id: "name", numeric: false, disablePadding: true, label: "Name" },
    { id: "description", numeric: false, disablePadding: true, label: "Description" },
    { id: "status", numeric: false, disablePadding: true, label: "Status",align:"center" },
    { id: "action", numeric: false, disablePadding: true, label: "Actions",align:"center" },
];

const displayRows = ["name", "show_view_btn", "showStatus", "actions"];

function ISOStandard() {
    const [isoStandards, setIsoStandards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState("add");
    const [dialogData, setDialogData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const snackRef = useRef()
    const openDesDialog = useRef(null)

    useEffect(() => {
        fetchAllStandards();
    }, []);

    const fetchAllStandards = async () => {
        setIsLoading(true);
        try {
            const response = await getAllStandards();
            setIsoStandards(response.data.data.isoStandards);
        } catch (err) {
            console.error("Error fetching companies:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogData(null);
        fetchAllStandards();
    };

    const handleOpenDialog = (type, data = null) => {
        setType(type);
        setDialogData(data);
        setOpenDialog(true);
    };

    const handleChangeStatus = (status, row) => {
        let params = {
            status
        }
        updateIsoStandardStatus(params, row?.id)
            .then(response => {
                snackRef.current.isOpenSnack({
                    snackType: "success",
                    snackMessage: "ISO Standard status updated successfully",
                });
                fetchAllStandards()
            })
            .catch(err => {
            })
    }

    return (
        <>
            <SnackbarAlert ref={snackRef} />
            <NavigationDrawer>
                <Loader isloading={isLoading} />

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="10px"
                    mt={"5em"}
                    mb={4}
                >
                <Typography variant="h5" fontSize={30} fontWeight={700} >
                    ISO Standards
                </Typography>
                    <Box height={40}>
                        <CustomButton
                            btnLabel="Add Standard"
                            handlePressBtn={() => handleOpenDialog("add")}
                        />
                    </Box>
                </Box>

                <DynamicTable
                    headerData={headerData}
                    bodyRows={isoStandards}
                    displayData={displayRows}
                    showEdit
                    openEditDialog={(row) => handleOpenDialog("edit", row)}
                    handleChangeStatus={(status, data) => handleChangeStatus(status, data)}
                    handlePressView={(row) => openDesDialog.current.isVisible({ data: row })}

                />

                <AddEditISOStandard
                    type={type}
                    onclosePress={handleCloseDialog}
                    fetchData={fetchAllStandards}
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    dialogData={dialogData}
                    setIsLoading={setIsLoading}
                />
                <DescriptionDialog ref={openDesDialog} />
            </NavigationDrawer>
        </>
    );
}

export default ISOStandard;

import React, { useRef, useState } from "react";
import {
  Typography,
  Grid,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TextInput from "../../../components/textInput";
import Loader from "../../../components/loader";
import AuthBackground from "../../../components/authBackground";
import CustomButton from "../../../components/customButton";
import CustomInputLabel from "../../../components/customInputLabel";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../redux/slices/Authentication";
import { LoginUser } from "../../../services/authentication";
import SnackbarAlert from "../../../components/snackbar";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import webLogoImg from "../../../assets/images/pdfLogo.jpg";

const Login = () => {
  const dispatch = useDispatch();
  const snackRef = useRef();
  const { userData } = useSelector((state) => state.Authentication);
  const [showPassword, setShowPassword] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    userName: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    if (inputValues.userName == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Username is required",
      });
    } else if (inputValues.password == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "password is required",
      });
    } else {
      let payload = {
        email: inputValues.userName,
        password: inputValues.password,
      };
      LoginUser(payload)
        .then((response) => {
          if (response.status != 200) {
            snackRef.current.isOpenSnack({
              snackType: "error",
              snackMessage: response?.data.message
                ? response?.data.message
                : response.data.data[0].message,
            });
          } else {
            let newUserObj = {
              ...response.data.data.auth.user,
              token: response.data.data.auth.token,
            };
            dispatch(setUserData(newUserObj));
            localStorage.setItem("userData", JSON.stringify(newUserObj));
          }
        })
        .catch((err) => {
        });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleLogin();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputValues]);



return (
  <AuthBackground>
    <SnackbarAlert ref={snackRef} />
    <Loader isloading={isloading} />
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: "10px",
        // maxWidth: "390px !important",
      }}
    >
      <img src={webLogoImg} style={{ width: "12em", marginBottom: "2em" }} />
      <Typography
        variant={"h2"}
        fontSize={30}
        fontWeight={700}
        textAlign={"center"}
        mb={2}
      >
        Login
      </Typography>
      <Typography variant={"body1"} fontSize={14} textAlign={"center"}>
        Welcome back! Please login to your account.
      </Typography>
      <Grid container mt={3} mb={2}>
        <Grid item xs={12} md={12}>
          <CustomInputLabel label={"Email"} />
          <TextInput
            type={"text"}
            placeholder={"Email"}
            name={"userName"}
            value={inputValues.userName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomInputLabel label={"Password"} />
          <TextInput
            placeholder={"Password"}
            type={showPassword ? "type" : "password"}
            name={"password"}
            value={inputValues.password}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <CustomButton
        btnLabel={"Login"}
        height={45}
        handlePressBtn={handleLogin}
      />
    </Container>
  </AuthBackground>
);
};

export default Login;

import React, { useEffect, useRef, useState } from "react";
import NavigationDrawer from "../../../../components/navigationDrawer";
import CustomInputLabel from "../../../../components/customInputLabel";
import TextInput from "../../../../components/textInput";
import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import CustomButton from "../../../../components/customButton";
import { getAllParticipants } from "../../../../services/participants";
import DynamicTable from "../../../../components/dynamicTable";
import AddEditAuditPlan from "./addEditAuditPlan";
import {
  createNextauditPlan,
  getNextAuditPlan,
  updateNextauditPlan,
} from "../../../../services/nextAuditPlan";
import { useSelector } from "react-redux";
import SnackbarAlert from "../../../../components/snackbar";
import { updateAuditStatus } from "../../../../services/auditors";

const headerData = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Day",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Time",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Auditor",
  },
  {
    id: "",
    numeric: false,
    disablePadding: true,
    label: "Process",
  },
  { id: "action", numeric: false, disablePadding: true, label: "Actions" },
];

const displayRows = ["id", "day", "time", "auditor", "process", "actions"];

const NextAuditPlan = () => {
  const navigate = useNavigate();
  const snackRef = useRef();
  const [inputs, setInputs] = useState({
    auditDate: "",
    auditor: "",
    auditDays: "",
    auditType: "",
  });
  const [tableData, setTableData] = useState([]);
  const [nextPlan, setNextPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("add");
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { userData } = useSelector((state) => state.Authentication);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { state } = useLocation();
  const [id, setId] = useState(null);

  useEffect(() => {
    fetchNextAuditPlan();
  }, []);

  const fetchNextAuditPlan = async () => {
    setIsLoading(true);
    try {
      const response = await getNextAuditPlan(
        {
          company_audit_id: state.companyId,
        },
        userData.token
      );

      if (response.status == 200) {
        if (response.data.data.getAuditReportNextAudit.length > 0) {
          setId(response.data.data.getAuditReportNextAudit[0].id);
          const {
            next_audit_date,
            next_audit_days,
            next_audit_type,
            next_auditor,
            next_audit_details,
          } = response.data.data.getAuditReportNextAudit[0];
          setInputs({
            auditDate: next_audit_date,
            auditDays: next_audit_days,
            auditor: next_auditor,
            auditType: next_audit_type,
          });
          setTableData(next_audit_details);
        }
        // setParticipants(response.data.data.getAuditReportOHSParticipants);
      }
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnInputChange = (event) => {
    const { name, value } = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleOpenDialog = (type, data = null) => {
    setType(type);
    setDialogData(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
  };

  const markAuditAsCompleted = async () => {
    const { companyId } = state;
    navigate("/next-steps", { state: { companyId } });
  };

  const onPressBtn = () => {
    // alert("NEXTTTTTTTTTT");
    let params = {
      ...(id != null && { id }),
      company_audit_id: state.companyId,
      next_audit_date: inputs.auditDate,
      next_audit_type: inputs.auditType,
      next_auditor: inputs.auditor,
      next_audit_days: inputs.auditDays,
      next_audit_details: tableData,
    };
    if (id != null) {
      updateNextauditPlan(params, userData.token)
        .then((response) => {
          if (response.status == 200) {
            snackRef.current.isOpenSnack({
              snackType: "success",
              snackMessage: "Next Plan Added Successfully",
            });
            fetchNextAuditPlan();
            // setTimeout(() => {
            markAuditAsCompleted();
            // }, 2000);
          } else {
            snackRef.current.isOpenSnack({
              snackType: "error",
              snackMessage: "Something went wrong",
            });
          }
        })
        .catch((err) => {
          console.log(err, "ERRRRRRRRRRR");
        });
    } else {
      createNextauditPlan(params, userData.token)
        .then((response) => {})
        .catch((err) => {
          console.log(err, "ERRRRRRRRRRR");
        });
    }
  };

  const onAddNewData = (data) => {
    setTableData([...tableData, data]);
  };

  const updateTableData = (data) => {
    let tempData = [...tableData];
    tempData[currentIndex] = data;
    setTableData(tempData);
  };

  return (
    <NavigationDrawer>
      <SnackbarAlert ref={snackRef} />
      <Box style={{ display: "flex", alignItems: "center" }} mb={3}>
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", marginRight: "12px" }}
        />
        <Typography variant="h5" fontSize={30} fontWeight={700}>
          Next Audit Plan
        </Typography>
      </Box>
      <Grid container spacing={2} alignItems={"flex-start"} mb={2}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Audit Date"} />
          <TextInput
            placeholder={"Audit Date"}
            value={inputs.auditDate}
            name={"auditDate"}
            onChange={(event) => handleOnInputChange(event, "auditDate")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Auditor Name"} />
          <TextInput
            placeholder={"Auditor Name"}
            value={inputs.auditor}
            name={"auditor"}
            onChange={(event) => handleOnInputChange(event, "auditor")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Audit Days"} />
          <TextInput
            placeholder={"3 days"}
            value={inputs.auditDays}
            name={"auditDays"}
            onChange={(event) => handleOnInputChange(event, "auditDays")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Audit Type"} />
          <TextInput
            placeholder={"Audit Type"}
            value={inputs.auditType}
            name={"auditType"}
            onChange={(event) => handleOnInputChange(event, "auditType")}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" gap="10px" mt={2} mb={2}>
        <Box height={40} sx={{ width: {} }}>
          <CustomButton
            btnLabel="Add Audit Details"
            handlePressBtn={() => handleOpenDialog("add")}
          />
        </Box>
      </Box>
      <DynamicTable
        headerData={headerData}
        bodyRows={tableData}
        displayData={displayRows}
        showEdit
        openEditDialog={(row, index) => {
          setCurrentIndex(index);
          handleOpenDialog("edit", row);
        }}
      />

      <div style={{ textAlign: "right", marginTop: "10px", height: "40px" }}>
        <CustomButton btnLabel="Next" handlePressBtn={onPressBtn} />
      </div>

      <AddEditAuditPlan
        type={type}
        onclosePress={handleCloseDialog}
        onAddNewData={(data) => onAddNewData(data)}
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogData={dialogData}
        updateTableData={updateTableData}
        setIsLoading={setIsLoading}
      />
    </NavigationDrawer>
  );
};

export default NextAuditPlan;

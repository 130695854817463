import { endPoints, requestType } from "../../constants/variables";
import Api from "../index";

export const getAllIsoSubClause = () => {
  return Api(endPoints.IsoSubClause, {}, requestType.POST);
};

export const linkISOtoSubClause = (params) => {
  return Api(endPoints.linkISOtoSubClause, params, requestType.POST);
};

export const fetchISOBySubClause = (params) => {
  return Api(`${endPoints.fetchSubClausesByID}`, params, requestType.POST);
};

import { Box, DialogContent, Grid, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/textInput";
import DialogFooter from "./../../../../components/dialog/dialogFooter";
import DialogHeader from "./../../../../components/dialog/dialogHeader";
import BootstrapDialog from "./../../../../components/dialog/index";
import { addCompany, updateCompany } from "../../../../services/company";
import SnackbarAlert from "../../../../components/snackbar";
import CustomInputLabel from "../../../../components/customInputLabel";
import {
  addSubClauses,
  updateSubClauses,
} from "../../../../services/subClauses";
import CustomDropDown from "../../../../components/customDropDown";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { stateFromHTML } from "draft-js-import-html";

function AddEditSubClause({
  type,
  open,
  handleClose,
  dialogData,
  fetchData,
  onclosePress,
  setIsLoading,
  clauses,
}) {
  const [inputVal, setInputVal] = useState({
    title: "",
    // description: "",
    subClausenumber: "",
    clauseID: "",
  });
  const [desc, setDesc] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    if (type === "edit" && dialogData) {
      setInputVal({
        title: dialogData.title,
        // description: ,
        subClausenumber: dialogData?.sub_clause_number,
        clauseID: dialogData?.clause_id,
      });
      // if(dialogData.description){
      //   const contentState = convertFromRaw(dialogData.description);
      //   setDesc(EditorState.createWithContent(contentState));
      // }

      if (dialogData.description) {
        try {
          const contentState = stateFromHTML(dialogData.description);
          setDesc(EditorState.createWithContent(contentState));
        } catch (error) {
          console.error("Error converting raw content state:", error);
          setDesc(EditorState.createEmpty());
        }
      } else {
        setDesc(EditorState.createEmpty());
      }
    } else {
      setInputVal({
        name: "",
        // description: "",
        subClausenumber: "",
        clauseID: "",
      });
      setDesc(EditorState.createEmpty());
    }
  }, [type, dialogData]);

  const handleChange = (e, name) => {
    setInputVal((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    const { clauseID, subClausenumber, title } = inputVal;
    let params = {
      title,
      description: draftToHtml(convertToRaw(desc.getCurrentContent())),
      sub_clause_number: subClausenumber,
    };

    if (type !== "edit") {
      params.clause_id = clauseID;
    }
    try {
      if (type === "add") {
        let result = await addSubClauses(params);
        if (result.status == 200) {
          snackRef.current.isOpenSnack({
            snackType: "success",
            snackMessage: "Sub Clause added successfully",
          });
          fetchData();
          handleClose();
          setInputVal({
            name: "",
            // description: "",
            subClausenumber: "",
            clauseID: "",
          });
          setDesc(EditorState.createEmpty());
        } else {
          snackRef.current.isOpenSnack({
            snackType: "error",
            snackMessage: result.data.message,
          });
        }
      } else {
        let result = await updateSubClauses({ id: dialogData.id, ...params });
        if (result.status == 200) {
          snackRef.current.isOpenSnack({
            snackType: "success",
            snackMessage: "Sub Clause updated successfully",
          });
          fetchData();
          handleClose();
          setInputVal({
            name: "",
            // description: "",
            subClausenumber: "",
            clauseID: "",
          });
          setDesc(EditorState.createEmpty());
        } else {
          snackRef.current.isOpenSnack({
            snackType: "error",
            snackMessage: result.data.message,
          });
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
      // handleClose();
    }
  };

  const onChangeDesc = (editorState) => {
    setDesc(editorState);
  };

  const snackRef = useRef();

  return (
    <Box>
      <SnackbarAlert ref={snackRef} />
      <BootstrapDialog
        onclosePress={onclosePress}
        onClose={onclosePress}
        open={open}
      >
        <DialogHeader
          title={type === "add" ? "Add Sub Clause" : "Edit Sub Clause"}
          handleClose={onclosePress}
        />
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <CustomDropDown
                selectedValue={inputVal.clauseID}
                fieldLabel={"Clause Name"}
                handleChange={(e) => handleChange(e, "clauseID")}
                fieldName={"clauseID"}
              >
                <MenuItem value={""}>Select Clause</MenuItem>
                {clauses.map((item) => {
                  return (
                    <MenuItem value={item.id}>{item.clause_title}</MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel label="Sub Clause Title" />
              <TextInput
                placeholder="Sub Clause Title"
                value={inputVal.title}
                onChange={(e) => handleChange(e, "title")}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomInputLabel label="Sub Clause Number" />
              <TextInput
                placeholder="Sub Clause Number"
                value={inputVal.subClausenumber}
                onChange={(e) => handleChange(e, "subClausenumber")}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel label="Sub Clause Description" />
              {/* <TextInput
                placeholder="Sub Clause Description"
                value={inputVal.description}
                onChange={(e) => handleChange(e, "description")}
                multiline={true}
                rows={4}
              />  */}
              <Editor
                editorStyle={{
                  height: "auto",
                  maxHeight: "300px",
                }}
                editorState={desc}
                onEditorStateChange={onChangeDesc}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "history",
                    "colorPicker",
                    "link",
                  ],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleSave}
          handleUpdate={handleSave}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </Box>
  );
}

export default AddEditSubClause;

import { Box, Checkbox, Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import useStyles from "../../globalStyles";
import { setClauseClick, setCurrentClause } from "../../redux/slices/allISO";
import {
  addUpdateAuditReport,
  fetchAuditReportSubClause,
} from "../../services/auditors";
import { fetchISOReports } from "../../services/subClauses";
import ActionEditorModal from "../actionEditorModal";
import CustomButton from "../customButton";
import EvidenceEditorDialog from "../evidenceEditorModal";
import SnackbarAlert from "../snackbar";

const TableComponent = ({ data, companyId, isoStandards, mainData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.Authentication);
  const { clauseClick, selectedInd, selectedContent, selectedPage } =
    useSelector((data) => data.allISO);
  const classes = useStyles();
  const snackRef = useRef();
  const [open, setOpen] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [activeItem, setActiveItem] = useState(null);
  const [selectedStandard, setSelectedStandard] = useState([
    {
      complaint: false,
      major: false,
      minor: false,
      ofi: false,
      not_applicable: false,
      comments: "",
      objective_evidence: "",
      audit_evidence: "",
    },
    {
      complaint: false,
      major: false,
      minor: false,
      ofi: false,
      not_applicable: false,
      comments: "",
      objective_evidence: "",
      audit_evidence: "",
    },
  ]);

  useEffect(() => {
    dispatch(
      setCurrentClause({
        selectedPage: selectedPage,
        selectedInd: selectedInd,
        selectedContent: data && data[0]?.data[0],
      })
    );
    getCurrentSelectedDetail(data && data[0]?.data[0]);
  }, [data]);

  useEffect(() => {
    getCurrentSelectedDetail(selectedContent);
  }, [clauseClick]);

  const getCurrentSelectedDetail = async (seletedObjjj) => {
    let filteredddd = mainData.filter((itemmmmmmm) => {
      return seletedObjjj.sub_clause_number == itemmmmmmm.sub_clause_number;
    });

    let newArrr = [];
    setIsLoading(true);
    await Promise.all(
      filteredddd.map(async (filterObj) => {
        let payloadd = {
          iso_standard_id: filterObj.iso_id,
          sub_clause_id: filterObj.sub_clause_id,
          company_audit_id: companyId,
        };
        try {
          let responseee = await fetchAuditReportSubClause(
            payloadd,
            userData.token
          );
          if (responseee.status >= 200 || responseee.status < 210) {
            let auditRes = responseee.data.data.subClauseAudit;
            let resulttt = isoStandards.find(
              (standddd) => standddd.value == filterObj.iso_id
            );
            if (auditRes.length > 0) {
              const {
                complaint,
                major,
                minor,
                ofi,
                not_applicable,
                comments,
                objective_evidence,
                audit_evidence,
              } = auditRes[0];

              newArrr.push({
                ...resulttt,
                suClauseId: filterObj.sub_clause_id,
                complaint: complaint !== "" ? complaint : false,
                major: major !== "" ? major : false,
                minor: minor !== "" ? minor : false,
                ofi: ofi !== "" ? ofi : false,
                not_applicable: not_applicable !== "" ? not_applicable : false,
                comments: comments || "",
                objective_evidence: objective_evidence || "",
                audit_evidence: audit_evidence || "",
              });
            } else {
              newArrr.push({
                ...resulttt,
                suClauseId: filterObj.sub_clause_id,
                complaint: false,
                major: false,
                minor: false,
                ofi: false,
                not_applicable: false,
                comments: "",
                objective_evidence: "",
                audit_evidence: "",
              });
            }
            // if (auditRes.length > 0) {
            //   if (auditRes[0]?.complaint !== "") {
            //     newArrr.push({
            //       ...resulttt,
            //       suClauseId: filterObj.sub_clause_id,
            //       complaint: auditRes[0]?.complaint,
            //       major: false,
            //       minor: false,
            //       ofi: false,
            //       notApplicable: false,
            //     });
            //   } else if (auditRes[0]?.major !== "") {
            //     newArrr.push({
            //       ...resulttt,
            //       suClauseId: filterObj.sub_clause_id,
            //       complaint: false,
            //       major: auditRes[0]?.major,
            //       minor: false,
            //       ofi: false,
            //       notApplicable: false,
            //     });
            //   } else if (auditRes[0]?.minor !== "") {
            //     newArrr.push({
            //       ...resulttt,
            //       suClauseId: filterObj.sub_clause_id,
            //       complaint: false,
            //       major: false,
            //       minor: auditRes[0]?.minor,
            //       ofi: false,
            //       notApplicable: false,
            //     });
            //   } else if (auditRes[0]?.ofi !== "") {
            //     newArrr.push({
            //       ...resulttt,
            //       suClauseId: filterObj.sub_clause_id,
            //       complaint: false,
            //       major: false,
            //       minor: false,
            //       ofi: auditRes[0]?.ofi,
            //       notApplicable: false,
            //     });
            //   } else if (auditRes[0]?.not_applicable) {
            //     newArrr.push({
            //       ...resulttt,
            //       suClauseId: filterObj.sub_clause_id,
            //       complaint: false,
            //       major: false,
            //       minor: false,
            //       ofi: false,
            //       notApplicable: false,
            //     });
            //   } else {
            //     newArrr.push({
            //       ...resulttt,
            //       suClauseId: filterObj.sub_clause_id,
            //       complaint: false,
            //       major: false,
            //       minor: false,
            //       ofi: false,
            //       notApplicable: false,
            //     });
            //   }
            // } else {
            //   newArrr.push({
            //     ...resulttt,
            //     suClauseId: filterObj.sub_clause_id,
            //     complaint: false,
            //     major: false,
            //     minor: false,
            //     ofi: false,
            //     notApplicable: false,
            //   });
            // }
          }
        } catch (error) {
          console.log(error, "errorerrorerrorerror");
        }
      })
    );

    setIsLoading(false);

    let sortedArrrrr = newArrr.sort((aaa, bbb) => {
      // Split For A
      let splittedValA = aaa.label.split(" ");
      let isoASplittedA = splittedValA[1].split(":");
      // Split For B
      let splittedValB = bbb.label.split(" ");
      let isoASplittedB = splittedValB[1].split(":");
      return isoASplittedA[0] - isoASplittedB[0];
    });
    setSelectedStandard(sortedArrrrr);
  };

  const handleNext = async () => {
    // let checkRadioVal = selectedStandard.find((valueeee) => {
    //   return valueeee.selectedRadio == "";
    // });
    // let checkVall = selectedStandard.find(
    //   (valueeee) => valueeee.textVal == "" || valueeee.textVal == undefined
    // );

    // if (checkRadioVal !== undefined) {
    //   snackRef.current.isOpenSnack({
    //     snackType: "error",
    //     snackMessage: `Please select any option for ISO ${checkRadioVal.label}`,
    //   });
    // } else if (selectedInd + 1 == data[selectedPage]?.data?.length && currentActionClause == "") {
    //   snackRef.current.isOpenSnack({
    //     snackType: "error",
    //     snackMessage: `Please enter action clause details`,
    //   });
    // } else {

    // if (
    //   selectedInd + 1 == data[selectedPage]?.data?.length &&
    //   currentActionClause == ""
    // )
    // if (selectedInd + 1 == data[selectedPage]?.data?.length) {
    //   snackRef.current.isOpenSnack({
    //     snackType: "error",
    //     snackMessage: `Please enter action clause details`,
    //   });
    // }
    //  else {
    setIsLoading(true);
    await Promise.all(
      selectedStandard.map(async (filterObj) => {
        let payloadddd = {
          iso_standard_id: filterObj?.value,
          sub_clause_id: filterObj?.suClauseId,
          company_audit_id: companyId,
          complaint: filterObj?.complaint,
          major: filterObj?.major,
          minor: filterObj?.minor,
          ofi: filterObj?.ofi,
          not_applicable: filterObj?.not_applicable,
          comments: filterObj?.comments,
          objective_evidence: filterObj?.objective_evidence,
          audit_evidence: filterObj?.audit_evidence,
        };
        try {
          let responseee = await addUpdateAuditReport(
            payloadddd,
            userData.token
          );
          if (responseee.status >= 200 || responseee.status < 210) {
          }
        } catch (error) {
          console.log(error, "errorerrorerrorerror");
        }
      })
    );
    setIsLoading(false);
    if (selectedInd == data[selectedPage].data.length - 1) {
      if (data.length == selectedPage + 1) {
        markAuditAsCompleted();
      } else {
        dispatch(
          setCurrentClause({
            selectedPage: selectedPage + 1,
            selectedInd: 0,
            selectedContent: data && data[selectedPage + 1].data[0],
          })
        );
        getCurrentSelectedDetail(data && data[selectedPage + 1].data[0]);
      }
    } else {
      dispatch(
        setCurrentClause({
          selectedPage: selectedPage,
          selectedInd: selectedInd + 1,
          selectedContent: data[selectedPage].data[selectedInd + 1],
        })
      );
      getCurrentSelectedDetail(data[selectedPage].data[selectedInd + 1]);
    }
    // }
  };

  const markAuditAsCompleted = async () => {
    navigate("/next-plan", { state: { companyId } });
    // navigate("/participant", { state: { companyId } });
    // navigate("/ohs-participants", { state: { companyId } });
    // let params = {
    //   company_audit_id: companyId,
    // };
    // fetchISOReports(params)
    //   .then((response) => {
    //     console.log(response, "ASdasdasdasdadasdasdasasd");
    //     const has450001 = response.data.data.isoStandards.some((item) =>
    //       item.iso_name.includes("45001")
    //     );
    //     if (has450001) {
    //       navigate("/ohs-participants", { state: { companyId } });
    //     } else {
    //       navigate("/participant", { state: { companyId } });
    //     }
    //     console.log(has450001, "has450001has450001has450001has450001");
    //   })
    //   .catch((err) => {
    //     console.log(err, "ERRRRRRRRRRRRR");
    //   });
  };
  // let params = {
  //   id: companyId,
  //   audit_status: "Completed",
  // };
  // let result = await updateAuditStatus(params);
  // console.log(result, "resultresultresult");
  // if (result.status == 200) {
  //   snackRef.current.isOpenSnack({
  //     snackType: "success",
  //     snackMessage: "Audit Completed Successfully",
  //   });
  // } else {
  //   snackRef.current.isOpenSnack({
  //     snackType: "error",
  //     snackMessage: result.data.message,
  //   });
  // }

  const handleChangeText = (eee, valueee, inddd) => {
    let temppppp = [...selectedStandard];
    temppppp[inddd][valueee] = eee.target.value;
    setSelectedStandard(temppppp);
  };

  const handleCheckVal = (index, field, isChecked) => {
    const updatedStandards = [...selectedStandard];
    updatedStandards[index][field] = isChecked;
    if (field === "not_applicable" && isChecked) {
      updatedStandards[index] = {
        ...updatedStandards[index],
        complaint: false,
        major: false,
        minor: false,
        ofi: false,
        not_applicable: true,
      };
    }
    setSelectedStandard(updatedStandards);
  };

  const onPressSave = (text) => {
    let tempArr = [...selectedStandard];
    tempArr[clickedIndex].audit_evidence = text;
    setSelectedStandard(tempArr);
  };

  return (
    <>
      <EvidenceEditorDialog
        open={open}
        handleClosed={() => setOpen(false)}
        setSelectedStandard={setSelectedStandard}
        selectedStandard={selectedStandard}
        activeEvidence={activeItem}
      />
      <ActionEditorModal
        open={openActionModal}
        handleClosed={() => setOpenActionModal(false)}
        setSelectedStandard={setSelectedStandard}
        selectedStandard={selectedStandard}
        activeItem={activeItem}
      />
      <Loader isloading={isLoading} />
      <SnackbarAlert ref={snackRef} />
      {/* <Button
        style={{ float: "right", marginTop: "-3.5em" }}
        variant="contained"
        size="medium"
        className={classes.globalBtnStyle}
        onClick={() => setOpen(true)}
      >
        Action
      </Button> */}
      <Box
        sx={{
          border: "1.2px solid black",
        }}
      >
        {data?.map((val, ind) => {
          if (ind == selectedPage) {
            return (
              <>
                <Grid container>
                  {val.data.map((item, index) => {
                    return (
                      <Grid
                        item
                        key={ind}
                        sx={{
                          border: "1px solid black",
                          padding: 2,
                          color: selectedInd === index && "white",
                          background:
                            selectedInd === index &&
                            "linear-gradient(to top,#fe8c00, #fc5b1f)",
                          // backgroundColor:
                          //   selectedInd === index ? "#ff8b5f" : "#ffffff",
                          cursor: "pointer",
                          flex: 1,
                        }}
                        onClick={() => {
                          dispatch(
                            setCurrentClause({
                              selectedPage: selectedPage,
                              selectedInd: index,
                              selectedContent: item,
                            })
                          );
                          dispatch(setClauseClick(!clauseClick));
                        }}
                      >
                        <Typography
                          variant="h1"
                          fontSize={"1rem"}
                          fontWeight={600}
                        >
                          {/* asdlkasjd */}
                          {item.sub_clause_number} {item.sub_clause_title}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>

                <Box p={2}>
                  <Box sx={{ width: "100%", marginY: "10px", marginX: "10px" }}>
                    <Typography
                      variant="h1"
                      fontSize={"1.3rem"}
                      textAlign={"center"}
                      fontWeight={600}
                      marginBottom="1em"
                    >
                      {selectedContent?.sub_clause_title}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={"0.9rem"}
                      fontWeight={400}
                    >
                      {/* {selectedContent?.sub_clause_description} */}
                      <td
                        dangerouslySetInnerHTML={{
                          __html: selectedContent?.sub_clause_description,
                        }}
                      />
                    </Typography>
                  </Box>
                  {/* <Box sx={{ width: "25%" }}>
                      <Typography
                        variant="h1"
                        fontSize={"1rem"}
                        fontWeight={500}
                        color={"#eb6938"}
                        sx={{ marginBottom: "10px" }}
                      >
                        Scope of the organization is :
                      </Typography>
                      <div style={{ width: "100%" }}>
                        <Select
                          defaultValue={selectedStandard}
                          value={selectedStandard}
                          onChange={(vallll) => handleChangeIsoStandard(vallll)}
                          options={selectedIsos}
                          placeholder="Please Select ISO Standards"
                          isMulti
                        />
                      </div>
                    </Box> */}
                  <Divider />

                  <Grid container spacing={3}>
                    {selectedStandard.map((itemmmmm, inddddd) => {
                      return (
                        <Grid item xs={12} md={6} lg={4}>
                          <Typography
                            variant="h4"
                            fontSize={"18px"}
                            fontWeight={700}
                            sx={{
                              marginY: "8px",
                              backgroundColor: "#f3f3f3",
                              background:
                                "linear-gradient(to left,  #f2f2f2,  #eaeaea,#dbdbdb)",
                              padding: "10px",
                            }}
                          >
                            For {itemmmmm.label}
                          </Typography>

                          <Stack
                            sx={{
                              ".MuiCheckbox-root.Mui-checked": {
                                color: "orange",
                              },
                            }}
                            alignItems="center"
                            direction={"row"}
                            gap={1}
                          >
                            <Checkbox
                              checked={itemmmmm.complaint}
                              onChange={(e) =>
                                handleCheckVal(
                                  inddddd,
                                  "complaint",
                                  e.target.checked
                                )
                              }
                              disabled={
                                itemmmmm.not_applicable ||
                                itemmmmm.minor ||
                                itemmmmm.major
                              }
                            />
                            <Typography
                              variant="h4"
                              fontSize={"1rem"}
                              fontWeight={600}
                            >
                              Compliant
                            </Typography>
                          </Stack>
                          <Stack
                            sx={{
                              ".MuiCheckbox-root.Mui-checked": {
                                color: "orange",
                              },
                            }}
                            alignItems="center"
                            direction={"row"}
                            gap={1}
                          >
                            <Checkbox
                              checked={itemmmmm.major}
                              onChange={(e) =>
                                handleCheckVal(
                                  inddddd,
                                  "major",
                                  e.target.checked
                                )
                              }
                              disabled={
                                itemmmmm.not_applicable ||
                                itemmmmm.ofi ||
                                itemmmmm.complaint
                              }
                            />
                            <Typography
                              variant="h4"
                              fontSize={"1rem"}
                              fontWeight={600}
                            >
                              Major NC
                            </Typography>
                          </Stack>
                          <Stack
                            sx={{
                              ".MuiCheckbox-root.Mui-checked": {
                                color: "orange",
                              },
                            }}
                            alignItems="center"
                            direction={"row"}
                            gap={1}
                          >
                            <Checkbox
                              checked={itemmmmm.minor}
                              onChange={(e) =>
                                handleCheckVal(
                                  inddddd,
                                  "minor",
                                  e.target.checked
                                )
                              }
                              disabled={
                                itemmmmm.not_applicable ||
                                itemmmmm.ofi ||
                                itemmmmm.complaint
                              }
                            />
                            <Typography
                              variant="h4"
                              fontSize={"1rem"}
                              fontWeight={600}
                            >
                              Minor NC
                            </Typography>
                          </Stack>
                          <Stack
                            sx={{
                              ".MuiCheckbox-root.Mui-checked": {
                                color: "orange",
                              },
                            }}
                            alignItems="center"
                            direction={"row"}
                            gap={1}
                          >
                            <Checkbox
                              checked={itemmmmm.ofi}
                              onChange={(e) =>
                                handleCheckVal(inddddd, "ofi", e.target.checked)
                              }
                              disabled={
                                itemmmmm.not_applicable ||
                                itemmmmm.minor ||
                                itemmmmm.major
                              }
                            />
                            <Typography
                              variant="h4"
                              fontSize={"1rem"}
                              fontWeight={600}
                            >
                              OFI
                            </Typography>
                          </Stack>
                          <Stack
                            sx={{
                              ".MuiCheckbox-root.Mui-checked": {
                                color: "orange",
                              },
                            }}
                            alignItems="center"
                            direction={"row"}
                            gap={1}
                          >
                            <Checkbox
                              disabled={
                                itemmmmm.major ||
                                itemmmmm.minor ||
                                itemmmmm.ofi ||
                                itemmmmm.complaint
                              }
                              checked={itemmmmm.not_applicable}
                              onChange={(e) =>
                                handleCheckVal(
                                  inddddd,
                                  "not_applicable",
                                  e.target.checked
                                )
                              }
                            />
                            <Typography
                              variant="h4"
                              fontSize={"1rem"}
                              fontWeight={600}
                            >
                              Not Applicable
                            </Typography>
                          </Stack>

                          <div style={{ width: "100%" }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                style={{
                                  marginTop: "5px",
                                  marginRight: "10px",
                                }}
                              >
                                <CustomButton
                                  styleProps={{
                                    width: "100%",
                                    height: "35px",
                                  }}
                                  btnLabel={
                                    itemmmmm.audit_evidence !== ""
                                      ? "Add Evidence"
                                      : "Add Evidence"
                                  }
                                  handlePressBtn={() => {
                                    setClickedIndex(inddddd);
                                    setOpen(true);
                                    setActiveItem({
                                      currentEvidence: itemmmmm,
                                      currentEviInd: inddddd,
                                    });
                                  }}
                                />
                              </Box>

                              {!itemmmmm.not_applicable && (
                                <Box
                                  style={{
                                    marginTop: "5px",
                                  }}
                                >
                                  <CustomButton
                                    styleProps={{
                                      width: "100%",
                                      height: "35px",
                                    }}
                                    // styleProps={{}}
                                    btnLabel={
                                      itemmmmm.comments !== ""
                                        ? "Add Description"
                                        : "Add Description"
                                    }
                                    handlePressBtn={() => {
                                      setClickedIndex(inddddd);
                                      setOpenActionModal(true);
                                      setActiveItem({
                                        currentItem: itemmmmm,
                                        currentItemInd: inddddd,
                                        type: "description",
                                      });
                                    }}
                                  />
                                </Box>
                              )}
                            </Box>
                            {itemmmmm.minor || itemmmmm.major ? (
                              <div style={{ display: "grid", width: "100%" }}>
                                <Box
                                  width={"100%"}
                                  style={{
                                    marginTop: "5px",
                                    width: "100%",
                                  }}
                                >
                                  <div style={{ width: "100%" }}>
                                    <CustomButton
                                      styleProps={{
                                        width: "100%",
                                        height: "35px",
                                      }}
                                      btnLabel={"Add Objective Evidence"}
                                      handlePressBtn={() => {
                                        setClickedIndex(inddddd);
                                        setOpenActionModal(true);
                                        setActiveItem({
                                          currentItem: itemmmmm,
                                          currentItemInd: inddddd,
                                          type: "objective",
                                        });
                                      }}
                                    />
                                  </div>
                                </Box>
                              </div>
                            ) : null}
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent:
                        selectedInd != 0 ? "space-between" : "flex-end",
                      marginTop: "50px",
                    }}
                  >
                    {selectedInd != 0 && (
                      <Box height={"40px"} width={"auto"} mt={1}>
                        <CustomButton
                          className={classes.globalBtnStyle}
                          handlePressBtn={() => {
                            dispatch(
                              setCurrentClause({
                                selectedPage: selectedPage,
                                selectedInd: selectedInd - 1,
                                selectedContent:
                                  data[selectedPage].data[selectedInd - 1],
                              })
                            );
                            getCurrentSelectedDetail(
                              data[selectedPage].data[selectedInd - 1]
                            );
                          }}
                          btnLabel={"Go Back"}
                        />
                      </Box>
                    )}

                    <Box height={"40px"} width={"auto"} mt={1}>
                      <CustomButton
                        variant="contained"
                        size="medium"
                        className={classes.globalBtnStyle}
                        handlePressBtn={handleNext}
                        btnLabel={
                          selectedInd + 1 != data[selectedPage]?.data?.length
                            ? "Next"
                            : (selectedInd + 1 ==
                                data[selectedPage]?.data?.length) &
                              (data.length == selectedPage + 1)
                            ? "Next"
                            : "Next Clause"
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            );
          }
        })}
      </Box>
      {/* <Box sx={{ width: "100%", textAlign: "right" }}>
        <Button
          style={{ marginTop: "10px" }}
          variant="contained"
          size="small"
          className={classes.globalBtnStyle}
          onClick={() => handleChangeMainPage(selectedPage + 1)}
        >
          {selectedPage + 1 != data.length ? "Next" : "Complete Audit"}
        </Button>
      </Box> */}
    </>
  );
};

export default TableComponent;

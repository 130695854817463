import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import useStyles from "../../globalStyles";
import { images } from "../../assets";
import { allColors } from "../../constants/colors";

const AuthBackground = ({ children }) => {
  const classes = useStyles();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={classes.root}>
      {/* boxShadow: "inset 0 0 0 2000px rgba(255, 0, 150, 0.3)", */}
      {/* boxShadow: "inset 0 0 0 2000px rgba(78, 190, 181, 0.5)" */}
      <Grid container sx={{ boxShadow: "inset 0 0 0 2000px rgba(255, 255, 255, 0.6)",backgroundSize:"cover",backgroundImage: `url(${"https://media.licdn.com/dms/image/D4D12AQH4q55wM-BitA/article-cover_image-shrink_600_2000/0/1709462909645?e=2147483647&v=beta&t=sPh9g9vVKG7-lWdXOfDk727zT6kPVU1q-LVPny9plh8"})`, height: "100%" , alignItems: "center" }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            margin: { xs: "0 10px", sm: "0 auto" },
            // position: "relative",
            // bottom: "-56px",
          }}
          className={classes.loginContainer}
        >
          
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthBackground;

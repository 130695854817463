import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import CustomButton from "../../../../components/customButton";
import AddEditClause from "./AddEditClause";
import { getAllClauses } from "../../../../services/clauses";

const headerData = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "title", numeric: false, disablePadding: true, label: "Clause Title" },
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Clause Number",
  },
  { id: "action", numeric: false, disablePadding: true, label: "Actions",align:"center" },
];

const displayRows = ["id", "clause_title", "clause_number", "actions"];

function Clauses() {
  const [clauses, setClauses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("add");
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchAllClauses();
  }, []);

  const fetchAllClauses = async () => {
    setIsLoading(true);
    try {
      const response = await getAllClauses();
      setClauses(response.data.data.clauses);
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
    getAllClauses();
  };

  const handleOpenDialog = (type, data = null) => {
    setType(type);
    setDialogData(data);
    setOpenDialog(true);
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
        mt={"5em"}
        mb={4}
      >
        <Typography variant="h5" fontSize={30} fontWeight={700}>
          Clauses
        </Typography>
        <Box height={40}>
          <CustomButton
            btnLabel="Add Clause"
            handlePressBtn={() => handleOpenDialog("add")}
          />
        </Box>
      </Box>

      <DynamicTable
        headerData={headerData}
        bodyRows={clauses}
        displayData={displayRows}
        showEdit
        showDelete
        openEditDialog={(row) => handleOpenDialog("edit", row)}
      />

      <AddEditClause
        type={type}
        onclosePress={handleCloseDialog}
        fetchData={fetchAllClauses}
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
      />
    </NavigationDrawer>
  );
}

export default Clauses;

import { endPoints, requestType } from "../../constants/variables";
import Api from "../index";

export const getNextAuditPlan = (params, token) => {
  return Api(endPoints.fetchNextAudit, params, requestType.POST, token);
};

export const createNextauditPlan = (params, token) => {
  return Api(endPoints.addNextAuditPlan, params, requestType.POST, token);
};

export const updateNextauditPlan = (params, token) => {
  return Api(
    `${endPoints.updateNextAuditPlan}`,
    params,
    requestType.POST,
    token
  );
};

//  Three year audit plan

export const fetchThreeYearPlan = (params, token) => {
  return Api(endPoints.fetchThreeYearPlan, params, requestType.POST, token);
};

export const addThreeYearAuditPlan = (params, token) => {
  return Api(
    `${endPoints.createThreeYearPlan}`,
    params,
    requestType.POST,
    token
  );
};

export const updateThreeYearAuditPlan = (params, token) => {
  return Api(
    `${endPoints.updateThreeYearPlan}`,
    params,
    requestType.POST,
    token
  );
};

import { Box, DialogContent, Grid } from "@mui/material";
import {
  ContentState,
  EditorState,
  convertToRaw
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import CustomInputLabel from "../customInputLabel";
import BootstrapDialog from "../dialog";
import DialogFooter from "../dialog/dialogFooter";
import DialogHeader from "../dialog/dialogHeader";
import Loader from "../loader";
import SnackbarAlert from "../snackbar";

function EvidenceEditorDialog({
  open,
  activeEvidence,
  handleClosed,
  selectedStandard,
  setSelectedStandard,
}) {
  const { userData } = useSelector((state) => state.Authentication);
  const [actionDesc, setActionDesc] = useState(() => EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(false);
  const snackRef = useRef();
  useEffect(() => {
    if (activeEvidence?.currentEvidence?.audit_evidence) {
      const blocksFromHtml = htmlToDraft(
        activeEvidence?.currentEvidence?.audit_evidence
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setActionDesc(editorState);
    } else {
      setActionDesc(EditorState.createEmpty());
    }
  }, [activeEvidence]);

  const handleSave = async () => {
    const actionContent = draftToHtml(
      convertToRaw(actionDesc.getCurrentContent())
    );
    let tempArrr = [...selectedStandard];
    tempArrr[activeEvidence?.currentEviInd].audit_evidence = actionContent;
    setSelectedStandard(tempArrr);
    handleClosed();
  };

  const onChangeAction = (editorState) => {
    setActionDesc(editorState);
  };

  return (
    <Box>
      <SnackbarAlert ref={snackRef} />
      <BootstrapDialog onClose={handleClosed} open={open}>
        <Loader isloading={isLoading} />
        <DialogHeader
          title={"Evidence"}
          handleClose={handleClosed}
        />
        <DialogContent dividers>
          <Grid container spacing={2} alignItems={"flex-end"}>
            <Grid item xs={12}>
              <CustomInputLabel label={"Evidence"} />
              <Editor
                editorStyle={{
                  height: "auto",
                  maxHeight: "300px",
                }}
                editorState={actionDesc}
                onEditorStateChange={onChangeAction}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "history",
                    "colorPicker",
                    "link",
                  ],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={"edit"}
          handleUpdate={handleSave}
          handleClose={handleClosed}
        />
      </BootstrapDialog>
    </Box>
  );
}

export default EvidenceEditorDialog;

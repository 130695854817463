import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import CustomButton from "../../../../components/customButton";
import AddEditAuditor from "./AddEditAuditor";
import { getAllAuditors, updateAuditorStatus } from "../../../../services/auditors";
import SnackbarAlert from "../../../../components/snackbar";

const headerData = [
  {
    id: "first name",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  {
    id: "last name",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    align:"center"
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    align:"center"
  },
];

const diplayRows = ["first_name", "last_name", "email", "showStatus", "actions"];

function Auditors() {
  const [isLoading, setIsLoading] = useState(false);
  const [auditors, setAuditors] = useState([]);
  const [type, setType] = useState("add");
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const snackRef = useRef()

  useEffect(() => {
    fetchAllAuditors()
  }, [])

  const fetchAllAuditors = async () => {
    setIsLoading(true);
    try {
      const response = await getAllAuditors();
      setAuditors(response.data.data.users);
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  }


  const handleOpenDialog = (type, data = null) => {
    setType(type);
    setDialogData(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
    fetchAllAuditors();
  };

  const handleOpenEdit = (row) => {
    setOpenDialog(true);
    setDialogData(row);
    setType("edit");
  };


  const handleChangeStatus = (status, row) => {
    let params = {
      status
    }
    updateAuditorStatus(params, row?.id)
      .then(response => {
        snackRef.current.isOpenSnack({
          snackType: "success",
          snackMessage: "Auditor status updated successfully",
        });
        fetchAllAuditors()
      })
      .catch(err => {
      })
  }


  return (
    <>
      <SnackbarAlert ref={snackRef} />
      <NavigationDrawer>
        <Loader isloading={isLoading} />

        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
          mt={"5em"}
          mb={4}
        >
          <Typography variant={"h5"} fontSize={30} fontWeight={700}>
            Auditors
          </Typography>
          <Box height={40}>
            <CustomButton
              btnLabel={"Add Auditor"}
              handlePressBtn={() => handleOpenDialog("add")}
            />
          </Box>
        </Box>

        <DynamicTable
          headerData={headerData}
          bodyRows={auditors}
          displayData={diplayRows}
          showEdit
          handleChangeStatus={(status, data) => handleChangeStatus(status, data)}
          openEditDialog={(row) => handleOpenDialog("edit", row)}
        />

        <AddEditAuditor
          type={type}
          onclosePress={handleCloseDialog}
          open={openDialog}
          fetchData={fetchAllAuditors}
          handleClose={handleCloseDialog}
          dialogData={dialogData}
          setIsLoading={setIsLoading}
        // fetchAllCategory={fetchAllCategory}
        />
      </NavigationDrawer>
    </>
  );
}

export default Auditors;

import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";
import HtmlParser from "../../../../components/htmlParser/htmlParser";

const EvidancePage = ({ item, arrayLength }) => {
  const renderSameField = (
    fieldd,
    valueeee,
    secondField,
    ofi,
    major,
    minor,
    indexxx
  ) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottom: arrayLength - 1 !== indexxx ? "1px solid black" : "",
        }}
      >
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "5px",
            width: "15%",
            height: "100%",
          }}
        >
          {fieldd}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "3px",
            paddingBottom: "3px",
            height: "100%",
            width: "37%",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <HtmlParser html={valueeee} />
        </Text>
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "37%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <HtmlParser html={secondField} />
        </Text>
        <View
          style={{
            width: "11%",
            height: "100%",
          }}
        >
          <Text
            style={{
              fontSize: "10px",
              fontWeight: "900",
              paddingTop: "3px",
              paddingBottom: "3px",
              textAlign: "center",
            }}
          >
            {ofi == "NC/OFI" ? "NC/OFI" : ofi == true ? "ofi" : ""}
          </Text>
          {major || minor ? (
            <Text
              style={{
                fontSize: "10px",
                fontWeight: "900",
                paddingTop: "3px",
                paddingBottom: "3px",
                textAlign: "center",
              }}
            >
              nc
            </Text>
          ) : null}
        </View>
      </View>
    );
  };

  const renderHeader = (fieldd, valueeee, secondField, secondValue) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "lightgray",
          borderBottom: "1px solid black",
        }}
      >
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "5px",
            width: "15%",
          }}
        >
          {fieldd}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "3px",
            paddingBottom: "3px",
            width: "37%",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {valueeee}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "37%",
          }}
        >
          {secondField}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "900",
            paddingTop: "3px",
            paddingBottom: "3px",
            textAlign: "center",
            width: "11%",
          }}
        >
          {secondValue}
        </Text>
      </View>
    );
  };

  return (
    <Page
      size={"A4"}
      wrap
      style={{
        paddingBottom: 65,
      }}
    >
      <PDFHeaderFooter>
        <View
          key={item.clause_id}
          style={{
            flexGrow: 1,
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            width: "90%",
            alignSelf: "center",
            marginBottom: "20px",
            overflow: "hidden",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "gray",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "700",
                marginTop: "2px",
                marginBottom: "2px",
                marginLeft: "3px",
              }}
            >
              {item.clause_number}. {item.clause_title}
            </Text>
          </View>

          {renderHeader("Clause", "Requirements", "Audit Evidance", "NC/OFI")}
          {item.subClauses.map((itemmm, indexxx) => {
            return renderSameField(
              itemmm.sub_clause_number,
              itemmm.requirement,
              itemmm.audit_evidence ? itemmm.audit_evidence : "No Evidence",
              itemmm.ofi,
              itemmm.major,
              itemmm.minor,
              indexxx
            );
          })}

          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              borderBottom: "1px solid black",
              padding: "4px 0",
              paddingLeft: "3px",
            }}
          >
            Clause 4 processes for QMS, EMS &, HSMS{" "}
            <Text style={{ color: "#eb6938" }}>are/are not</Text> working in
            compliance with the company’s policy and objectives
          </Text>
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default EvidancePage;

import React, { useEffect, useRef, useState } from "react";
import NavigationDrawer from "../../../../components/navigationDrawer";
import { createNextSteps, getNextSteps } from "../../../../services/nextSteps";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/system";
import CustomButton from "../../../../components/customButton";
import { updateAuditStatus } from "../../../../services/auditors";
import SnackbarAlert from "../../../../components/snackbar";

const CustomRadio = styled(Radio)({
  color: "#FFB74D",
  "&.Mui-checked": {
    color: "#FFB74D",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&:focus-visible": {
    outline: "none",
  },
});

const NextSteps = () => {
  const [allSteps, setAllSteps] = useState([]);
  const [answers, setAnswers] = useState({});
  const { state } = useLocation();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.Authentication);
  useEffect(() => {
    fetchNextPlan();
  }, []);

  const fetchNextPlan = () => {
    getNextSteps(
      {
        company_audit_id: state.companyId,
      },
      userData.token
    )
      .then((response) => {
        setAllSteps(response.data.data.getAuditReportNotes);
      })
      .catch((err) => {
        console.log(err, "ERRRRRRRRRRR");
      });
  };

  const handleChange = (id, value, data) => {
    let params = {
      company_audit_id: state.companyId,
      audit_note_id: id,
      answer: value,
    };
    createNextSteps(params, userData.token)
      .then((response) => {
        fetchNextPlan();
      })
      .catch((err) => {
        console.log(err, "ERRRRRRRRRRRr");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // You can send answers to your API here
  };

  const handleButtonPress = async () => {
    const { companyId } = state;
    navigate("/three-year-plan", { state: { companyId } });
    // let params = {
    //   id: state.companyId,
    //   audit_status: "Completed",
    // };
    // let result = await updateAuditStatus(params);
    // console.log(result, "resultresultresult");
    // if (result.status == 200) {
    //   snackRef.current.isOpenSnack({
    //     snackType: "success",
    //     snackMessage: "Audit Completed Successfully",
    //   });
    // } else {
    //   snackRef.current.isOpenSnack({
    //     snackType: "error",
    //     snackMessage: result.data.message,
    //   });
    // }
  };

  const snackRef = useRef();

  return (
    <NavigationDrawer>
      <SnackbarAlert ref={snackRef} />
      <Box style={{ display: "flex", alignItems: "center" }} mb={3}>
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", marginRight: "12px" }}
        />
        <Typography variant="h5" fontSize={{ xs: 20, sm: 30 }} fontWeight={700}>
          Next Steps
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        {allSteps.map((q, index) => {
          return (
            <div>
              <FormControl key={q.id} margin="normal">
                <FormLabel style={{ color: "black" }}>{q.question}</FormLabel>
                <RadioGroup
                  row
                  name={`question-${q.id}`}
                  value={allSteps[index]?.answer || ""}
                  onChange={(e) => handleChange(q.id, e.target.value, q)}
                >
                  <FormControlLabel
                    value="Y"
                    control={<CustomRadio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="N"
                    control={<CustomRadio />}
                    label="No"
                  />
                  <FormControlLabel
                    value="Full"
                    control={<CustomRadio />}
                    label="Full"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          );
        })}
      </form>
      <div style={{ textAlign: "right" }}>
        <CustomButton
          btnLabel={"Add 3 years Audit Plan"}
          handlePressBtn={handleButtonPress}
        />
      </div>
    </NavigationDrawer>
  );
};

export default NextSteps;

import { View, Text, Image } from "@react-pdf/renderer";
import pdfLogo from "../../assets/images/pdfLogo.jpg";

const PDFHeaderFooter = ({ children }) => {
  let date = new Date().getMonth();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <>
      <View
        fixed
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingHorizontal: "13px",
          marginVertical: "10px",
          paddingVertical:"10px"
        }}
      >
        <Image src={pdfLogo} style={{ height: "30px", width: "27%" }} />
        <Text style={{ fontSize: "36px", fontWeight: "600", color: "#ed7d31" }}>
          <Text style={{ fontSize: "36px", fontWeight: "600", color: "black" }}>
            Audit
          </Text>{" "}
          Report
        </Text>
      </View>

      <View
        style={{
          alignItems: "center",
          // height: 550,
        }}
      >
        {children}
      </View>

      <View
        fixed
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#fff",
        }}
      >
        <Text
          style={{ fontSize: "8px", color: "black", marginLeft: "18px" }}
          render={
            ({ pageNumber, totalPages }) => `Page | ${pageNumber}`
            // `Page | ${pageNumber} / ${totalPages}`
          }
        />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingHorizontal: "18px",
            paddingVertical: "10px",
          }}
        >
          <Text
            style={{
              fontSize: "8px",
              color: "black",
              width: "55%",
              textAlign: "justify",
            }}
          >
            FF1/A Audit, Issue Level 18.4, {months[date]} 2024 This report
            remains the Property of ForeFront Certification Limited, Basepoint
            Business Centre, Isidore Road, Bromsgrove Enterprise Park,
            Bromsgrove, B60 3ET |Co Registration: 9014372 |
          </Text>
          <Text style={{ fontSize: "18px", color: "#ed7d31" }}>ffcert.com</Text>
        </View>
      </View>
    </>
  );
};

export default PDFHeaderFooter;

import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";

const tableData = [
  {
    id: 1,
    finding: "Major NC",
    totalNo: "0",
    standard: "",
    clauses: "",
    actionTaken: "",
    isCloed: "",
  },
  {
    id: 2,
    finding: "Major NC",
    totalNo: "0",
    standard: "",
    clauses: "",
    actionTaken: "",
    isCloed: "",
  },
  {
    id: 3,
    finding: "PNC",
    totalNo: "1",
    standard: "14001",
    clauses: "6.1.2",
    actionTaken:
      "F-(SOP-QA-13)-01 IMS Aspect & Impact Assessments, v2 has not considered abnormal or emergency situations for the company. Action: Aspects are reviewed and include abnormal and emergencies",
    isCloed: "Y",
  },
  {
    id: 4,
    finding: "PNC",
    totalNo: "2",
    standard: "14001",
    clauses: "7.1",
    actionTaken:
      "It appears that waste management and environmental controls are primarily managed by the landlord. However, it's important to ensure that the company maintains oversight and takes responsibility for these aspects as part of its duty of care. Action: The landlord has provided documentation, assessed in clause 8",
    isCloed: "Y",
  },
];

const PageFive = () => (
  <Page size={"A4"}>
    <PDFHeaderFooter>
      <View style={{ width: "80%" }}>
        <Text
          style={{
            fontSize: "14px",
            fontWeight: "700",
            marginTop: "10px",
            marginBottom: "4px",
          }}
        >
          Open Audit findings from previous assessment visit(s):
        </Text>
      </View>

      <View
        style={{
          border: "1px solid black",
          width: "80%",
          alignSelf: "center",
          marginTop: "6px",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: "1px solid black",
          }}
        >
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Finding
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Total {"\n"} Number
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Standard
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Clauses
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              width: "35%",
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Actions Taken
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              paddingTop: "2px",
            }}
          >
            Closed {"\n"} Y/N
          </Text>
        </View>
        {tableData.map((itemmm, indexxxx) => {
          return (
            <View
              key={itemmm.id}
              style={{
                flexDirection: "row",
                borderBottom:
                  tableData.length - 1 !== indexxxx
                    ? "1px solid black"
                    : "none",
              }}
            >
              <View
                style={{
                  padding: 5,
                  borderRight: "1px solid black",
                  flex: 1,
                }}
              >
                <Text style={{ fontSize: "11px", fontWeight: "900" }}>
                  {itemmm.finding}
                </Text>
              </View>
              <View
                style={{
                  padding: 5,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                  {itemmm.totalNo}
                </Text>
              </View>
              <View
                style={{
                  padding: 5,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                  {itemmm.standard}
                </Text>
              </View>
              <View
                style={{
                  padding: 5,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                  {itemmm.clauses}
                </Text>
              </View>
              <View
                style={{
                  padding: 5,
                  borderRight: "1px solid black",
                  width: "35%",
                }}
              >
                <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                  {itemmm.actionTaken}
                </Text>
              </View>
              <View
                style={{
                  padding: 5,
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "9px", fontWeight: "700" }}>
                  {itemmm.isCloed}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </PDFHeaderFooter>
  </Page>
);

export default PageFive;

import { Edit, RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { allColors, primaryColor } from "../../constants/colors";
import CustomButton from "../customButton";
import ImageResolver from "./ImageResolver";
import dynamicTableStyle from "./style";
import CustomDropDown from "../customDropDown";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { headerData, order, orderBy, onRequestSort, classes } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ ".MuiTableCell-root": { fontWeight: "bold" } }}>
      <TableRow className={classes.headerRowsStyle}>
        {headerData?.map((headCell) => (
          <TableCell
            // style={{backgroundColor:"#e4e4e4"}}
            key={headCell.id}
            align={headCell.align || "left"}
            // align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label !== "Actions" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function DynamicTable({
  headerData,
  bodyRows,
  displayData,
  onChangeDropDownValue,
  showView,
  showEdit,
  showDelete,
  openEditDialog,
  handleDelete,
  navigateToDetail,
  handleChangeStatus,
  handlePressBtn,
  handlePressView,
  handlePressPDF,
}) {
  const classes = dynamicTableStyle();
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = headerData?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleOpenImage = (row) => {
    window.open(row.image, "_blank");
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: "rgb(201 199 199)",
      cursor: "pointer",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      }}
    >
      <TableContainer>
        <Table sx={{ minWidth: 750 }} size="large" stickyHeader>
          <EnhancedTableHead
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={bodyRows?.length}
            headerData={headerData}
            classes={classes}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(bodyRows, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    // padding="normal"
                    className={classes.tableRowsStyle}
                    sx={{
                      padding: "5em",
                      // "& .MuiTableCell-root ": {
                      //   backgroundColor: "#fff",
                      // },
                    }}
                  >
                    {displayData?.map((val, i) => {
                      return (
                        <React.Fragment key={val}>
                          {val == "id" ? (
                            <TableCell padding="normal">{index + 1}</TableCell>
                          ) : val == "image" || val == "coverImage" ? (
                            <ImageResolver row={row} />
                          ) : val == "logoImage" ? (
                            <ImageResolver row={row} />
                          ) : val == "showAuditBtn" ? (
                            <TableCell padding="normal" align="center">
                              <Box height={30} margin={"auto"}>
                                <CustomButton
                                  btnLabel={
                                    row.audit_status == "In-Progress"
                                      ? "In-Progress"
                                      : row.audit_status == "Completed"
                                      ? "Completed"
                                      : "Start Auditing"
                                  }
                                  disabled={
                                    row.audit_status == "Cancelled"
                                      ? true
                                      : false
                                  }
                                  handlePressBtn={() => handlePressBtn(row)}
                                  btnTextSize={"13px"}
                                />
                              </Box>
                            </TableCell>
                          ) : val == "surveillanceDrop" ? (
                            <TableCell padding="normal" align="center">
                              <Box width={"75%"} margin={"auto"}>
                                <CustomDropDown
                                  fieldName={"surveillance"}
                                  selectedValue={
                                    row.audit_type ? row.audit_type : ""
                                  }
                                  handleChange={(a, b) => {
                                    onChangeDropDownValue(a.target.value, row);
                                  }}
                                  // selectedValue={inputVal.openMeeting}
                                >
                                  <MenuItem value={""}>Select Option</MenuItem>
                                  {[
                                    "Surveillance 1",
                                    "Surveillance 2",
                                    "Recertification",
                                  ].map((item) => {
                                    return (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })}
                                </CustomDropDown>
                              </Box>
                            </TableCell>
                          ) : val == "showPdfButton" ? (
                            <TableCell padding="normal">
                              <Box
                                height={30}
                                sx={{
                                  width: { xs: "100%", md: "auto" },
                                }}
                              >
                                <CustomButton
                                  btnLabel={
                                    row.audit_status == "Completed"
                                      ? "Download PDF"
                                      : "Complete Audit First"
                                  }
                                  disabled={
                                    row.audit_status == "Completed"
                                      ? false
                                      : true
                                  }
                                  handlePressBtn={() => handlePressPDF(row)}
                                  btnTextSize={"13px"}
                                />
                              </Box>
                            </TableCell>
                          ) : val == "showStatus" ? (
                            <TableCell align="center">
                              <FormControlLabel
                                control={
                                  <Switch
                                    sx={{
                                      ".MuiSwitch-thumb": {
                                        backgroundColor: "white",
                                      },
                                      ".Mui-checked+.MuiSwitch-track": {
                                        backgroundColor: "#f34105 !important",
                                      },
                                    }}
                                    size="small"
                                    checked={
                                      row.deleted_at == null ? true : false
                                    }
                                    onChange={(e) =>
                                      handleChangeStatus(e.target.checked, row)
                                    }
                                  />
                                }
                              />
                            </TableCell>
                          ) : val == "show_view_btn" ? (
                            <TableCell>
                              {row.description?.length > 30 ? (
                                <CustomButton
                                  btnLabel={"View"}
                                  width={100}
                                  handlePressBtn={() => handlePressView(row)}
                                />
                              ) : (
                                row.description
                              )}
                            </TableCell>
                          ) : val == "auditor_fullname" ? (
                            <TableCell sx={{ textTransform: "capitalize" }}>
                              {`${row.first_name} ${row.last_name}`}
                            </TableCell>
                          ) : val !== "actions" ? (
                            <TableCell padding="normal">{row[val]}</TableCell>
                          ) : (
                            <TableCell padding="normal" align="center">
                              {showView && (
                                <RemoveRedEye
                                  style={{
                                    color: primaryColor,
                                    fontSize: "22px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                  }}
                                  onClick={() => navigateToDetail(row)}
                                />
                              )}
                              {showEdit && (
                                <Edit
                                  style={{
                                    marginRight: showDelete && "15px",
                                    color: allColors.orange,
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => openEditDialog(row, index)}
                                />
                              )}
                              {/* {showDelete && (
                                <Delete
                                  style={{
                                    color: "red",
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDelete(row, index)}
                                />
                              )} */}
                            </TableCell>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={bodyRows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ backgroundColor: "#fff" }}
      />
    </Box>
  );
}

import { endPoints, requestType } from "../../constants/variables";
import Api from "../index";

export const getAllAuditors = () => {
  return Api(endPoints.auditor, {}, requestType.POST);
};

export const addAuditor = (params) => {
  return Api(endPoints.addAuditor, params, requestType.POST);
};

export const updateAuditor = (params) => {
  return Api(endPoints.updateAuditor, params, requestType.POST);
};

export const updateAuditorStatus = (params, id) => {
  return Api(
    `${endPoints.updateAuditorStatus}/${id}`,
    params,
    requestType.POST
  );
};

export const updateAuditStatus = (params) => {
  return Api(`${endPoints.updateAuditStatus}`, params, requestType.POST);
};

export const addUpdateAuditReport = (params, token) => {
  return Api(
    `${endPoints.addEditAuditReport}`,
    params,
    requestType.POST,
    token
  );
};

export const addUpdateActionClause = (params, token) => {
  return Api(
    `${endPoints.addEditActionClause}`,
    params,
    requestType.POST,
    token
  );
};

export const fetchAuditReportSubClause = (parms, token) => {
  return Api(endPoints.fetchReportSubClause, parms, requestType.POST, token);
};

export const fetchAuditReportActionClause = (parms, token) => {
  return Api(endPoints.fetchReportActionClause, parms, requestType.POST, token);
};

export const fetchAuditReport = (parms, token) => {
  return Api(endPoints.auditReportDetail, parms, requestType.POST, token);
};


export const addUpdateFinalReportDetails = (params, token) => {
  return Api(
    `${endPoints.addEditFinalReport}`,
    params,
    requestType.POST,
    token
  );
};

import { endPoints, requestType } from "../../constants/variables";
import Api from "../index";

const assignCompanyToAuditor = (params) => {
  return Api(endPoints.assignCompanyToAuditor, params, requestType.POST);
};

const fetchAllAuditorList = (params) => {
  return Api(endPoints.fetchAllAuditorList, {}, requestType.POST);
};

const updateCompanyToAuditor = (params, id) => {
  return Api(
    `${endPoints.updateCompanyToAuditor}/${id}`,
    params,
    requestType.POST
  );
};

const getAssignedCompanies = (token) => {
  return Api(endPoints.getAssignedCompanies, {}, requestType.POST, token);
};

export {
  assignCompanyToAuditor,
  fetchAllAuditorList,
  updateCompanyToAuditor,
  getAssignedCompanies,
};

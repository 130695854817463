import React from "react";
import { Text, View } from "@react-pdf/renderer";

const HtmlParser = ({ html }) => {
  const parseHtml = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return Array.from(div.childNodes).map((node, index) =>
      renderNode(node, index)
    );
  };

  const renderNode = (node, key) => {
    if (node.nodeType === 3) {
      return <Text key={key} style={styles.text}>{node.nodeValue}</Text>;
    }

    if (node.nodeType === 1) {
      switch (node.nodeName.toLowerCase()) {
        case "h1":
          return <Text style={styles.h1} key={key}>{node.textContent}</Text>;
        case "h2":
          return <Text style={styles.h2} key={key}>{node.textContent}</Text>;
        // Other headers...
        case "p":
          return (
            <Text style={styles.p} key={key}>
              {Array.from(node.childNodes).map((child, index) =>
                renderNode(child, index)
              )}
            </Text>
          );
        case "strong":
          return <Text style={styles.strong} key={key}>{node.textContent}</Text>;
        case "em":
          return <Text style={styles.em} key={key}>{node.textContent}</Text>;
        case "br":
          return <Text key={key}>{"\n"}</Text>;
        case "ul":
          return (
            <View style={styles.ul} key={key}>
              {Array.from(node.childNodes).map((child, index) =>
                renderNode(child, index)
              )}
            </View>
          );
        case "li":
          return (
            <View style={styles.li} key={key}>
              <Text style={styles.bullet}>• </Text>
              {Array.from(node.childNodes).map((child, index) =>
                renderNode(child, index)
              )}
            </View>
          );
        case "a":
          return <Text style={styles.a} key={key}>{node.textContent}</Text>;
        // Handle more tags...
        default:
          return <Text key={key}>{node.textContent}</Text>;
      }
    }
  };

  const parsedContent = parseHtml(html);
  return <View style={styles.container}>{parsedContent}</View>;
};

const styles = {
  container: { margin: 0, padding: 0 },
  text: { margin: 0, padding: 0 },
  h1: { fontSize: 34, fontWeight: "bold", margin: 0, padding: 0 },
  h2: { fontSize: 32, fontWeight: "bold", margin: 0, padding: 0 },
  // Other styles...
  p: { marginBottom: 10, margin: 0, padding: 0 },
  strong: { fontWeight: "bold", margin: 0, padding: 0 },
  em: { fontStyle: "italic", margin: 0, padding: 0 },
  ul: { marginBottom: 0, paddingLeft: 10, marginTop: 0 },
  li: { flexDirection: "row", marginBottom: 0, marginTop: 0 },
  bullet: { marginRight: 5 }, // Adjust bullet position if needed
  a: { color: "blue", textDecoration: "underline", margin: 0, padding: 0 },
};

export default HtmlParser;

import { useSelector } from "react-redux";
import { Navigate, useOutlet } from "react-router-dom";

export const ProtectedLayout = () => {
  const { userData } = useSelector((state) => state.Authentication);

  const outlet = useOutlet();

  if (!userData) {
    return <Navigate to="/login" />;
  }

  return <div>{outlet}</div>;
};

export const AuthProtectedLayout = () => {
  const { userData } = useSelector((state) => state.Authentication);

  const outlet = useOutlet();

  if (userData) {
    return <Navigate to="/" />;
  }

  return <div>{outlet}</div>;
};

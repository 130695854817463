import { Box, DialogContent, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/textInput";
import DialogFooter from "./../../../../components/dialog/dialogFooter";
import DialogHeader from "./../../../../components/dialog/dialogHeader";
import BootstrapDialog from "./../../../../components/dialog/index";
import { addCompany, updateCompany } from "../../../../services/company";
import SnackbarAlert from "../../../../components/snackbar";
import CustomInputLabel from "../../../../components/customInputLabel";

function AddEditCompany({
  type,
  open,
  handleClose,
  dialogData,
  fetchData,
  onclosePress,
  setIsLoading,
}) {
  const [inputVal, setInputVal] = useState({ name: "", description: "" });

  useEffect(() => {
    if (type === "edit" && dialogData) {
      setInputVal({
        name: dialogData.name,
        description: dialogData.description,
      });
    } else {
      setInputVal({ name: "", description: "" });
    }
  }, [type, dialogData]);

  const handleChange = (e, name) => {
    setInputVal((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleSave = async () => {
    if (inputVal.description == "") {
      return snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Name is requried",
      });
    }
    if (inputVal.description == "") {
      return snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Description is required",
      });
    }

    setIsLoading(true);
    try {
      if (type === "add") {
        let result = await addCompany(inputVal);
        if (result.status == 200) {
          snackRef.current.isOpenSnack({
            snackType: "success",
            snackMessage: "Company added successfully",
          });
          fetchData();
          handleClose();
          setInputVal({ name: "", description: "" });
        } else {
          snackRef.current.isOpenSnack({
            snackType: "error",
            snackMessage: result.data.message,
          });
        }
        // snackRef.current.isOpenSnack({
        //   snackType: "success",
        //   snackMessage: "Company added successfully",
        // });
      } else {
        await updateCompany({ id: dialogData.id, ...inputVal });
        snackRef.current.isOpenSnack({
          snackType: "success",
          snackMessage: "Company updated successfully",
        });
      }
      fetchData();
    } catch (err) {
      console.error("Error saving company:", err);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const snackRef = useRef();

  return (
    <Box>
      <SnackbarAlert ref={snackRef} />
      <BootstrapDialog
        onclosePress={onclosePress}
        onClose={onclosePress}
        open={open}
      >
        <DialogHeader
          title={type === "add" ? "Add Company" : "Edit Company"}
          handleClose={onclosePress}
        />
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <CustomInputLabel label="Company Name" />
              <TextInput
                placeholder="Company Name"
                value={inputVal.name}
                onChange={(e) => handleChange(e, "name")}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel label="Company Description" />
              <TextInput
                placeholder="Company Description"
                value={inputVal.description}
                onChange={(e) => handleChange(e, "description")}
                multiline={true}
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleSave}
          handleUpdate={handleSave}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </Box>
  );
}

export default AddEditCompany;

import { endPoints, requestType } from '../../constants/variables';
import Api from "../index";

export const getAllClauses = () => {
    return Api(endPoints.clauses, {}, requestType.POST);
};

export const addClause = (params) => {
    return Api(endPoints.addClause, params, requestType.POST);
}

export const updateClause = (params) => {
    return Api(endPoints.updateClause, params, requestType.POST);
}

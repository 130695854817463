import React, { useEffect, useRef, useState } from "react";
import NavigationDrawer from "../../../../components/navigationDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Container,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomInputLabel from "../../../../components/customInputLabel";
import TextInput from "../../../../components/textInput";
import CustomButton from "../../../../components/customButton";
import {
  addThreeYearAuditPlan,
  fetchThreeYearPlan,
  updateThreeYearAuditPlan,
} from "../../../../services/nextAuditPlan";
import { useSelector } from "react-redux";
import SnackbarAlert from "../../../../components/snackbar";
import Loader from "../../../../components/loader";
import { updateAuditStatus } from "../../../../services/auditors";

const initialData = [
  {
    id: 1,
    name: "Site tour / Work environment",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 2,
    name: "Policies",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 3,
    name: "Leadership interview",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 4,
    name: "Document and record control",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 5,
    name: "Resources, roles, responsibilities and authorities",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 6,
    name: "Training, Competence and organisational knowledge",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 7,
    name: "Awareness and communication",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 8,
    name: "Sales order processing",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 9,
    name: "Purchasing, supplier controls",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 10,
    name: "Site visit: Facilities Management",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 11,
    name: "Site visit: Security Guarding",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 12,
    name: "Maintenance / Infrastructure",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 13,
    name: "Environmental Aspects",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 14,
    name: "Waste Management",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 15,
    name: "COSHH",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 16,
    name: "Emergency Preparedness and response",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 17,
    name: "Legal compliance and evaluation of compliance",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 18,
    name: "Hazard identification and risk assessments",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 19,
    name: "Consultation and participation",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 20,
    name: "Recertification",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 21,
    name: "ISO 45001 only: Specific interviews programmed over the cycle for:• Employee representatives(s) with responsibility for OH&S, • Personnel responsible for monitoring employees' health, • Managers and permanent and temporary employees • Managersand employees performing activities related to the prevention of OH&S Safety risks •Contractors’ management and employees.",
    sv1: false,
    sv2: false,
    sv3: false,
  },
  {
    id: 22,
    name: "Closing meeting",
    sv1: false,
    sv2: false,
    sv3: false,
  },
];

const ThreeYearPlan = () => {
  const { userData } = useSelector((state) => state.Authentication);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    surveillance_one_days: "",
    surveillance_one_date: "",
    surveillance_two_days: "",
    surveillance_two_date: "",
    recert_days: "",
    recert_date: "",
    planID: null,
  });
  const snackRef = useRef();

  const getThreeYearPlan = () => {
    let companyDetail = JSON.parse(localStorage.getItem("companyDetail"));
    let objjj = {
      company_audit_id: companyDetail.id,
      audit_type: companyDetail.audit_type,
    };
    setIsLoading(true);
    fetchThreeYearPlan(objjj, userData.token)
      .then((responsee) => {
        setIsLoading(false);
        if (responsee.data.data.auditNextYearsPlan.length > 0) {
          let resultt = responsee.data.data.auditNextYearsPlan[0];
          setInputs({
            surveillance_one_days: resultt.surveillance_one_days,
            surveillance_one_date: resultt.surveillance_one_date,
            surveillance_two_days: resultt.surveillance_two_days,
            surveillance_two_date: resultt.surveillance_two_date,
            recert_days: resultt.recert_days,
            recert_date: resultt.recert_date,
            planID: resultt.id,
          });
          setData(resultt.next_years_plan);
        } else {
          setData(initialData);
        }
      })
      .catch((Errrr) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getThreeYearPlan();
  }, []);

  const handleCheckboxChange = (index, field) => {
    const newData = [...data];
    newData[index][field] = !newData[index][field];
    setData(newData);
  };

  const handleOnInputChange = (event, type) => {
    const { name, value } = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const completeAudit = async () => {
    let companyDetail = JSON.parse(localStorage.getItem("companyDetail"));
    let params = {
      id: state.companyId,
      audit_status: "Completed",
      audit_type: companyDetail.audit_type,
    };
    let result = await updateAuditStatus(params);
    if (result.status == 200) {
      navigate("/meeting-agenda");
      // snackRef.current.isOpenSnack({
      //   snackType: "success",
      //   snackMessage: "Audit Completed Successfully",
      // });
      // setTimeout(() => {
      //   navigate("/")
      // }, 1500);
    } else {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: result.data.message,
      });
    }
  };

  const handleAuditPlan = () => {
    if (inputs.surveillance_one_days == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter Surveillance one days",
      });
    } else if (inputs.surveillance_one_date == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please slect Surveillance one date",
      });
    } else if (inputs.surveillance_two_days == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter Surveillance two days",
      });
    } else if (inputs.surveillance_two_date == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please slect Surveillance two date",
      });
    } else if (inputs.recert_days == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter Recert days",
      });
    } else if (inputs.recert_date == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please slect Recert date",
      });
    } else {
      let companyDetail = JSON.parse(localStorage.getItem("companyDetail"));
      let params = {
        ...(inputs.planID != null && { id: inputs.planID }),
        company_id: companyDetail.company_id,
        company_audit_id: companyDetail.id,
        surveillance_one_days: inputs.surveillance_one_days,
        surveillance_one_date: inputs.surveillance_one_date,
        surveillance_two_days: inputs.surveillance_two_days,
        surveillance_two_date: inputs.surveillance_two_date,
        recert_days: inputs.recert_days,
        recert_date: inputs.recert_date,
        audit_type: companyDetail.audit_type,
        next_years_plan: data,
      };
      setIsLoading(true);
      if (inputs.planID != null) {
        updateThreeYearAuditPlan(params, userData.token)
          .then((response) => {
            setIsLoading(false);
            if (response.status == 200 || response.status == 201) {
              getThreeYearPlan();
              completeAudit();
              // snackRef.current.isOpenSnack({
              //   snackType: "success",
              //   snackMessage: "Three year plan updated Successfully",
              // });
            } else {
              snackRef.current.isOpenSnack({
                snackType: "error",
                snackMessage: response.data.message,
              });
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err, "ERRRRRRRRRRR");
          });
      } else {
        addThreeYearAuditPlan(params, userData.token)
          .then((response) => {
            setIsLoading(false);
            if (response.status == 200 || response.status == 201) {
              getThreeYearPlan();
              snackRef.current.isOpenSnack({
                snackType: "success",
                snackMessage: "Three year plan added Successfully",
              });
            } else {
              snackRef.current.isOpenSnack({
                snackType: "error",
                snackMessage: response.data.message,
              });
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err, "ERRRRRRRRRRR");
          });
      }
    }
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />

      <Box style={{ display: "flex", alignItems: "center" }} mb={3}>
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", marginRight: "12px" }}
        />
        <Typography variant="h5" fontSize={{ xs: 20, sm: 30 }} fontWeight={700}>
          3 Year Audit Plan
        </Typography>
      </Box>
      <Grid container spacing={2} alignItems={"flex-start"} mb={2}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Surveillance One Days"} />
          <TextInput
            placeholder={"1"}
            value={inputs.surveillance_one_days}
            name={"surveillance_one_days"}
            onChange={(event) =>
              handleOnInputChange(event, "surveillance_one_days")
            }
            type={"number"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Surveillance One Date"} />
          <TextInput
            type={"date"}
            placeholder={"1"}
            value={inputs.surveillance_one_date}
            name={"surveillance_one_date"}
            onChange={(event) =>
              handleOnInputChange(event, "surveillance_one_date")
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Surveillance Two Days"} />
          <TextInput
            placeholder={"1"}
            value={inputs.surveillance_two_days}
            name={"surveillance_two_days"}
            onChange={(event) =>
              handleOnInputChange(event, "surveillance_two_days")
            }
            type={"number"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Surveillance One Date"} />
          <TextInput
            type={"date"}
            placeholder={"1"}
            value={inputs.surveillance_two_date}
            name={"surveillance_two_date"}
            onChange={(event) =>
              handleOnInputChange(event, "surveillance_two_date")
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Recert Days"} />
          <TextInput
            placeholder={"1"}
            value={inputs.recert_days}
            name={"recert_days"}
            onChange={(event) => handleOnInputChange(event, "recert_days")}
            type={"number"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomInputLabel label={"Recert Date"} />
          <TextInput
            type={"date"}
            placeholder={"1"}
            value={inputs.recert_date}
            name={"recert_date"}
            onChange={(event) => handleOnInputChange(event, "recert_date")}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Surveillance1</TableCell>
              <TableCell align="center">Surveillance2</TableCell>
              <TableCell align="center">Recertification</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.sv1}
                    onChange={() => handleCheckboxChange(index, "sv1")}
                    sx={{
                      color: row.sv1 ? "orange" : "",
                      "&.Mui-checked": {
                        color: "orange",
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.sv2}
                    onChange={() => handleCheckboxChange(index, "sv2")}
                    sx={{
                      color: row.sv2 ? "orange" : "",
                      "&.Mui-checked": {
                        color: "orange",
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.sv3}
                    onChange={() => handleCheckboxChange(index, "sv3")}
                    sx={{
                      color: row.sv3 ? "orange" : "",
                      "&.Mui-checked": {
                        color: "orange",
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <CustomButton
          btnLabel={"Complete Audit"}
          handlePressBtn={handleAuditPlan}
        />
      </div>
      <SnackbarAlert ref={snackRef} />
    </NavigationDrawer>
  );
};

export default ThreeYearPlan;

import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import CustomButton from "../../../../components/customButton";
import AddEditParticipant from "./AddEditParticipant";
import { getAllParticipants } from "../../../../services/participants";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { updateAuditStatus } from "../../../../services/auditors";
import SnackbarAlert from "../../../../components/snackbar";
import { fetchISOReports } from "../../../../services/subClauses";

const headerData = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Participant Name",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Position",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Open Meeting",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Close Meeting",
  },
  { id: "action", numeric: false, disablePadding: true, label: "Actions" },
];

const displayRows = [
  "id",
  "participant_name",
  "participant_position",
  "opening_meeting",
  "closing_meeting",
  "actions",
];

function Participants() {
  const { userData } = useSelector((state) => state.Authentication);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("add");
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  let snackRef = useRef();
  useEffect(() => {
    fetchAllParticipants();
  }, []);

  const fetchAllParticipants = async () => {
    setIsLoading(true);
    try {
      const response = await getAllParticipants(
        {
          company_audit_id: state.id,
        },
        userData.token
      );
      if (response.data?.data) {
        setParticipants(response.data.data.getAuditReportParticipants);
      }
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
  };

  const handleOpenDialog = (type, data = null) => {
    setType(type);
    setDialogData(data);
    setOpenDialog(true);
  };

  const onNextPress = async () => {
    // console.log(state, "statestatestatestatestate");
    // const { companyId } = state;
    // navigate("/next-plan", { state });

    let params = {
      company_audit_id: state.id,
    };
    fetchISOReports(params)
      .then((response) => {
        const has450001 = response.data.data.isoStandards.some((item) =>
          item.iso_name.includes("45001")
        );
        if (has450001) {
          navigate("/ohs-participants", { state });
        } else {
          navigate("/start-auditing", { state });
        }
      })
      .catch((err) => {
        console.log(err, "ERRRRRRRRRRRRR");
      });
  };
  // let params = {
  //   id: state.companyId,
  //   audit_status: "Completed",
  // };
  // let result = await updateAuditStatus(params);
  // console.log(result, "resultresultresult");
  // if (result.status == 200) {
  //   snackRef.current.isOpenSnack({
  //     snackType: "success",
  //     snackMessage: "Audit Completed Successfully",
  //   });
  //   setTimeout(() => {
  //     navigate("/");
  //   }, 2000);
  // } else {
  //   snackRef.current.isOpenSnack({
  //     snackType: "error",
  //     snackMessage: result.data.message,
  //   });
  // }

  return (
    <NavigationDrawer>
      <SnackbarAlert ref={snackRef} />
      <Loader isloading={isLoading} />
      <Box style={{ display: "flex", alignItems: "center" }} mb={3}>
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", marginRight: "12px" }}
        />
        <Typography variant="h5" fontSize={30} fontWeight={700}>
          Participants
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" gap="10px" mt={2} mb={2}>
        <Box height={40} sx={{ width: {} }}>
          <CustomButton
            btnLabel="Add Participant"
            handlePressBtn={() => handleOpenDialog("add")}
          />
        </Box>
      </Box>

      <DynamicTable
        headerData={headerData}
        bodyRows={participants}
        displayData={displayRows}
        showEdit
        // showDelete
        openEditDialog={(row) => handleOpenDialog("edit", row)}
      />

      <div style={{ textAlign: "right", marginTop: "10px", height: "40px" }}>
        <Box height={40} sx={{ width: {} }}>
          <CustomButton btnLabel="Next" handlePressBtn={onNextPress} />
        </Box>
      </div>

      <AddEditParticipant
        type={type}
        onclosePress={handleCloseDialog}
        fetchData={fetchAllParticipants}
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogData={dialogData}
        setIsLoading={setIsLoading}
        companyId={state.id}
      />
    </NavigationDrawer>
  );
}

export default Participants;

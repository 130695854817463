import { Box, DialogContent, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/textInput";
import DialogFooter from "./../../../../components/dialog/dialogFooter";
import DialogHeader from "./../../../../components/dialog/dialogHeader";
import BootstrapDialog from "./../../../../components/dialog/index";
import SnackbarAlert from "../../../../components/snackbar";
import CustomInputLabel from "../../../../components/customInputLabel";
import { addClause, updateClause } from "../../../../services/clauses";

function AddEditClause({
    type,
    open,
    handleClose,
    dialogData,
    fetchData,
    onclosePress,
    setIsLoading,
}) {
    const [inputVal, setInputVal] = useState({ title: "", number: "" });

    useEffect(() => {
        if (type === "edit" && dialogData) {
            setInputVal({
                title: dialogData.clause_title,
                number: dialogData.clause_number,
            });
        } else {
            setInputVal({ title: "", number: "" });
        }
    }, [type, dialogData]);

    const handleChange = (e, name) => {
        setInputVal((prev) => ({
            ...prev,
            [name]: e.target.value,
        }));
    };

    const handleSave = async () => {
        setIsLoading(true);
        const { number, title } = inputVal
        let params = {
            clause_title: title,
            clause_number: number
        }
        try {
            if (type === "add") {
                await addClause(params);
                snackRef.current.isOpenSnack({
                    snackType: "success",
                    snackMessage: "Clause added successfully",
                });
                setInputVal({ title: "", number: "" });
            } else {
                await updateClause({ id: dialogData.id, ...params });
                snackRef.current.isOpenSnack({
                    snackType: "success",
                    snackMessage: "Clause updated successfully",
                });
                setInputVal({ title: "", number: "" });
            }
            fetchData();
        } catch (err) {
            console.error("Error saving company:", err);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    const snackRef = useRef();

    return (
        <Box>
            <SnackbarAlert ref={snackRef} />
            <BootstrapDialog onclosePress={onclosePress} onClose={onclosePress} open={open}>
                <DialogHeader
                    title={type === "add" ? "Add Clause" : "Edit Clause"}
                    handleClose={onclosePress}
                />
                <DialogContent dividers>
                    <Grid container spacing={2} alignItems="flex-end">
                        <Grid item xs={12}>
                            <CustomInputLabel label="Clause Title" />
                            <TextInput
                                placeholder="Clause Title"
                                value={inputVal.title}
                                onChange={(e) => handleChange(e, "title")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInputLabel label="Clause Number" />
                            <TextInput
                                type={"number"}
                                placeholder="Clause Number"
                                value={inputVal.number}
                                onChange={(e) => handleChange(e, "number")}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogFooter
                    type={type}
                    handleCreate={handleSave}
                    handleUpdate={handleSave}
                    handleClose={handleClose}
                />
            </BootstrapDialog>
        </Box>
    );
}

export default AddEditClause;

export const requestType = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

export const endPoints = {
  login: "login",

  companies: "companies/all",
  addCompany: "company/add",
  updateCompany: "company/update",
  updateCompanyStatus: "company/status",

  auditor: "users/all",
  addAuditor: "user/add",
  updateAuditor: "user/update",
  updateAuditorStatus: "user/status",

  iso: "iso/all",
  addISO: "iso/add",
  updateISO: "iso/update",
  updateIsoStandardStatus: "iso/status",

  clauses: "clauses",
  addClause: "clause/add",
  updateClause: "clause/update",

  subClause: "subclauses/all",
  addSubClause: "subclause/add",
  updateSubClause: "subclause/update",

  IsoSubClause: "iso/subclauses/all",
  linkISOtoSubClause: "iso/subclause/add",

  fetchSubClausesByID: "all/iso/subclause",

  // Assign company to auditor
  assignCompanyToAuditor: "company/auditor/add",
  fetchAllAuditorList: "company/auditor/list",
  updateCompanyToAuditor: "company/auditor/update",
  getAssignedCompanies: "assigned/companies",

  updateAuditStatus: "company/audit/status",
  addEditAuditReport: "add/report/subclause",
  fetchReportSubClause: "fetch/report/subclause",
  fetchReportActionClause: "fetch/report/clause",
  addEditActionClause: "add/report/clause",
  auditReportDetail: "fetch/report/details",
  addEditFinalReport: "add/report/details",

  fetchReportISO: "fetch/report/iso",

  // Participants
  reportParticipants: "fetch/report/participant",
  addReportParticipants: "add/report/participant",
  updateReportParticipants: "update/report/participant",

  fetchPdfData: "fetch/audit/report",

  // OHS participants

  fetchOhs: "fetch/report/ohs/participant",
  addOhs: "add/report/ohs/participant",
  updateOhs: "update/report/ohs/participant",

  // next audit plan

  fetchNextAudit: "fetch/report/nextaudit",
  updateNextAuditPlan: "update/report/nextaudit",
  addNextAuditPlan: "add/report/nextaudit",

  // three year audit plan
  fetchThreeYearPlan: "fetch/audit/plans",
  createThreeYearPlan: "add/audit/plans",
  updateThreeYearPlan: "update/audit/plans",

  // next steps

  fetchNextSteps: "fetch/report/notes",
  addNextSteps: "add/report/notes",
};

export const constants = {};

import React, { useEffect, useRef, useState } from "react";
import { Box, DialogContent, Grid, TextField } from "@mui/material";
import CustomInputLabel from "../customInputLabel";
import BootstrapDialog from "../dialog";
import DialogFooter from "../dialog/dialogFooter";
import DialogHeader from "../dialog/dialogHeader";
import SnackbarAlert from "../snackbar";
import { constants } from "../../constants/variables";

function ActionEditorModal({
  open,
  activeItem,
  handleClosed,
  selectedStandard,
  setSelectedStandard,
}) {
  const handleClose = () => {
    handleClosed();
    setActionDesc("");
    setObjective("");
  };

  const [actionDesc, setActionDesc] = useState("");
  const [objective, setObjective] = useState("");
  const snackRef = useRef();

  useEffect(() => {
    if (activeItem?.currentItem?.comments) {
      setActionDesc(activeItem?.currentItem?.comments);
      setObjective(activeItem?.currentItem?.objective_evidence);
    }
  }, [activeItem]);

  const handleSave = async () => {
    if (actionDesc == "") {
      constants.snackRef.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter action description",
      });
    } else if (
      (activeItem?.currentItem?.major || activeItem?.currentItem?.minor) &&
      objective == ""
    ) {
      constants.snackRef.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter objective detail",
      });
    } else {
      let tempArrr = [...selectedStandard];
      tempArrr[activeItem?.currentItemInd].comments = actionDesc;
      tempArrr[activeItem?.currentItemInd].objective_evidence = objective;
      setSelectedStandard(tempArrr);
      handleClose();
      setActionDesc("");
      setObjective("");
    }
  };

  return (
    <Box>
      <SnackbarAlert ref={snackRef} />
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogHeader
          title={
            activeItem?.type == "objective"
              ? "Objective Evidence"
              : "Description"
          }
          handleClose={handleClose}
        />
        <DialogContent dividers>
          <Grid container spacing={2} alignItems={"flex-end"}>
            {activeItem?.type == "description" ? (
              <Grid item xs={12}>
                <CustomInputLabel label={"Description"} />
                <TextField
                  label="Description"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "orange !important",
                      },
                    },
                  }}
                  variant="outlined"
                  placeholder={`Please enter details`}
                  fullWidth
                  margin="normal"
                  value={actionDesc}
                  multiline
                  size="small"
                  InputProps={{
                    style: { border: "1.2px solid #00000040" },
                  }}
                  onChange={(e) => setActionDesc(e.target.value)}
                  minRows={3}
                />
              </Grid>
            ) : null}
            {activeItem?.type == "objective" ? (
              <Grid item xs={12}>
                <CustomInputLabel label={"Objective Evidence"} />
                <TextField
                  label="Objective Evidence"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "orange !important",
                      },
                    },
                  }}
                  variant="outlined"
                  placeholder={`Please enter objective details`}
                  fullWidth
                  margin="normal"
                  value={objective}
                  multiline
                  size="small"
                  InputProps={{
                    style: { border: "1.2px solid #00000040" },
                  }}
                  onChange={(e) => setObjective(e.target.value)}
                  minRows={3}
                />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogFooter
          type={"edit"}
          handleUpdate={handleSave}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </Box>
  );
}

export default ActionEditorModal;

import { Page, View, Text, Font } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";
import HtmlParser from "../../../../components/htmlParser/htmlParser";

const PageThree = ({ reportDetails }) => {
  const renderSameField = (label, value) => {
    return (
      <View
        style={{
          alignItems: "center",
          width: "auto",
        }}
      >
        <Text style={{ fontSize: 11, fontWeight: "800", color: "#black" }}>
          {label}
        </Text>
        <View
          style={{
            border: "1px solid black",
            height: "10px",
            width: "10px",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 5,
            marginTop: "6px",
          }}
        />
      </View>
    );
  };
  return (
    <Page
      size={"A4"}
      wrap
      style={{
        paddingBottom: 65,
      }}
    >
      <PDFHeaderFooter>
        <View
          style={{
            width: "80%",
            alignSelf: "center",
            marginTop: "20px",
            paddingBottom: "10px",
            flexGrow: 1,
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: "700",
              textDecoration: "underline",
            }}
          >
            Report Introduction and Executive Summary
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: "700",
              marginVertical: 12,
            }}
          >
            Audit objectives, scope and criteria
          </Text>
          <Text
            style={{
              fontSize: 11,
              lineHeight: "1.5px",
            }}
          >
            <HtmlParser html={reportDetails.audit_objective} />
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: "700",
              marginVertical: 12,
            }}
          >
            Summary of Audit and Conclusion:
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "700",
              marginBottom: 4,
            }}
          >
            Audit Summary
          </Text>
          <Text
            style={{
              fontSize: 11,
              fontWeight: "700",
            }}
          >
            <HtmlParser html={reportDetails.audit_summary} />
          </Text>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            {renderSameField("Granted")}
            {renderSameField("Continued")}
            {renderSameField("Updated for scope extension")}
            {renderSameField("Suspended")}
          </View>
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default PageThree;

import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";

const PageTen = ({ auditNotes }) => {
  const renderSameField = (fieldd, valueeee, indexxx, arrLength) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottom: arrLength - 1 !== indexxx ? "1px solid black" : "",
        }}
      >
        <Text
          style={{
            fontSize: "11px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "2px",
            paddingLeft: "5px",
            width: "90%",
          }}
        >
          {fieldd}
        </Text>
        <Text
          style={{
            fontSize: "11px",
            fontWeight: "600",
            paddingTop: "2px",
            flex: 1,
            textAlign: "center",
          }}
        >
          {valueeee}
        </Text>
      </View>
    );
  };

  return (
    <Page size={"A4"}>
      <PDFHeaderFooter>
        <View style={{ width: "80%" }}>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: "700",
              marginTop: "10px",
              marginBottom: "4px",
            }}
          >
            Audit notes (for client on request)
          </Text>
        </View>

        <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "6px",
          }}
        >
          {auditNotes.map((itemmm, indexxx) => {
            return renderSameField(
              itemmm.question,
              itemmm.answer,
              indexxx,
              auditNotes.length
            );
          })}
        </View>
        {/* <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "12px",
          }}
        >
          {tableData2.map((itemmm, indexxx) => {
            return renderSameField(
              itemmm.description,
              itemmm.value,
              indexxx,
              tableData2.length
            );
          })}
        </View> */}
      </PDFHeaderFooter>
    </Page>
  );
};

export default PageTen;

import axios from "axios";

export let baseUrl = "http://forefront-report-be.tritact.co.uk/v1/";

const api = async (path, params, method, Xtoken) => {
  let options;
  options = {
    headers: {
      "Content-Type": "application/json",
      ...(Xtoken && { "x-token": Xtoken }),
    },
    method: method,
    ...(params && { data: JSON.stringify(params) }),
  };
  return axios(baseUrl + path, options)
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      return error.response;
    });
};

export default api;

import React from "react";
import { TableCell } from "@mui/material";

const ImageResolver = ({ row }) => {
  return (
    <TableCell padding="normal">
      <img
        src={
          row.image
            ? row.image
            : row.coverImage
            ? row.coverImage
            : row.logoImage
            ? row.logoImage
            : null
        }
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      />
    </TableCell>
  );
};

export default ImageResolver;

import { createSlice } from "@reduxjs/toolkit"

export const Login = createSlice({
    name: "Authentication",
    initialState: {
        userData: null,
        userToken: null
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload
        },
        setUserToken: (state, action) => {
            state.userToken = action.payload
        }
    }
})

export const { setUserData, setUserToken } = Login.actions

export default Login.reducer
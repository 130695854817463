import { Box, DialogContent, Grid, MenuItem, Typography } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import TextInput from "../../../../components/textInput";
import DialogFooter from "./../../../../components/dialog/dialogFooter";
import DialogHeader from "./../../../../components/dialog/dialogHeader";
import BootstrapDialog from "./../../../../components/dialog/index";
import { addCompany, updateCompany } from "../../../../services/company";
import SnackbarAlert from "../../../../components/snackbar";
import CustomInputLabel from "../../../../components/customInputLabel";
import {
  addSubClauses,
  updateSubClauses,
} from "../../../../services/subClauses";
import CustomDropDown from "../../../../components/customDropDown";
import { linkISOtoSubClause } from "../../../../services/linkIsotoSubClause";

let paramsData = {};

const DescriptionDialog = forwardRef(({}, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      paramsData = params;
      setOpen(true);
    },
  }));

  const onclosePress = () => {
    setOpen(false);
  };


  return (
    <Box>
      <BootstrapDialog
        onclosePress={onclosePress}
        onClose={onclosePress}
        open={open}
      >
        <DialogHeader title={"Description"} handleClose={onclosePress} />
        <DialogContent dividers>
          <Typography>{paramsData?.data?.description}</Typography>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
});

export default DescriptionDialog;

import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import CustomButton from "../../../../components/customButton";
import AddEditCompany from "./AddEditCompany";
import { getAllCompanies, updateCompanyStatus } from "../../../../services/company";
import SnackbarAlert from "../../../../components/snackbar";
import DescriptionDialog from "./DescriptionDialog";

const headerData = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "description", numeric: false, disablePadding: true, label: "Description" },
  { id: "Status", numeric: false, disablePadding: true, label: "Status",align:"center" },
  { id: "action", numeric: false, disablePadding: true, label: "Actions",align:"center" },
];

const displayRows = ["name", "show_view_btn", "showStatus", "actions"];

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("add");
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const snackRef = useRef()
  const openDesDialog = useRef()

  useEffect(() => {
    fetchAllCompanies();
  }, []);

  const fetchAllCompanies = async () => {
    setIsLoading(true);
    try {
      const response = await getAllCompanies();
      setCompanies(response.data.data.companies);
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
    fetchAllCompanies();
  };

  const handleOpenDialog = (type, data = null) => {
    setType(type);
    setDialogData(data);
    setOpenDialog(true);
  };

  const handleChangeStatus = (status, row) => {
    let params = {
      status
    }
    updateCompanyStatus(params, row?.id)
      .then(response => {
        snackRef.current.isOpenSnack({
          snackType: "success",
          snackMessage: "Company status updated successfully",
        });
        fetchAllCompanies()
      })
      .catch(err => {
      })
  }

  return (
    <>
      <SnackbarAlert ref={snackRef} />
      <NavigationDrawer>
        <Loader isloading={isLoading} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
          mt={"5em"}
          mb={4}
        >

          <Typography variant="h4" fontSize={27} fontWeight={600} >
            Companies
          </Typography>
          <Box height={40} >
            <CustomButton
              btnLabel="Add Company"
              handlePressBtn={() => handleOpenDialog("add")}
            />
          </Box>

        </Box>

        <DynamicTable
          headerData={headerData}
          bodyRows={companies}
          displayData={displayRows}
          showEdit
          handleChangeStatus={(status, data) => handleChangeStatus(status, data)}
          showDelete
          openEditDialog={(row) => handleOpenDialog("edit", row)}
          handlePressView={(row) => openDesDialog.current.isVisible({ data: row })}
        />

        <AddEditCompany
          type={type}
          onclosePress={handleCloseDialog}
          fetchData={fetchAllCompanies}
          open={openDialog}
          handleClose={handleCloseDialog}
          dialogData={dialogData}
          setIsLoading={setIsLoading}
        />
        <DescriptionDialog ref={openDesDialog} />
      </NavigationDrawer>
    </>
  );
}

export default Companies;

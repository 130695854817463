import { endPoints, requestType } from "../../constants/variables";
import Api from "../index";

export const getAllOHSParticipants = (params, token) => {
  return Api(endPoints.fetchOhs, params, requestType.POST, token);
};

export const createOHSParticipants = (params, token) => {
  return Api(endPoints.addOhs, params, requestType.POST, token);
};

export const updateOHSParticipants = (params, token) => {
  return Api(endPoints.updateOhs, params, requestType.POST, token);
};

import { Box, DialogContent, Grid, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/textInput";
import DialogFooter from "./../../../../components/dialog/dialogFooter";
import DialogHeader from "./../../../../components/dialog/dialogHeader";
import BootstrapDialog from "./../../../../components/dialog/index";
import { addCompany, updateCompany } from "../../../../services/company";
import SnackbarAlert from "../../../../components/snackbar";
import CustomInputLabel from "../../../../components/customInputLabel";
import {
  addSubClauses,
  updateSubClauses,
} from "../../../../services/subClauses";
import CustomDropDown from "../../../../components/customDropDown";
import { linkISOtoSubClause } from "../../../../services/linkIsotoSubClause";

function AddEditLinktoSubClause({
  type,
  open,
  handleClose,
  dialogData,
  fetchData,
  onclosePress,
  setIsLoading,
  isoStandards,
  allClauses,
}) {
  const [inputVal, setInputVal] = useState({
    isoID: "",
    subClauseID: "",
  });

  useEffect(() => {
    if (type === "edit" && dialogData) {
      setInputVal({});
    } else {
      setInputVal({
        isoID: "",
        subClauseID: "",
      });
    }
  }, [type, dialogData]);

  const handleChange = (e, name) => {
    if (name == "IsoID") {
      setInputVal({ ...inputVal, isoID: e.target.value });
    } else {
      setInputVal({ ...inputVal, subClauseID: e.target.value });
    }
    //     setInputVal((prev) => ({
    //         ...prev,
    //         [name]: value,
    //     }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    const { isoID, subClauseID } = inputVal;
    let params = {
      iso_standard_id: isoID,
      sub_clause_id: subClauseID,
    };
    try {
      if (type === "add") {
        let result = await linkISOtoSubClause(params);
        if (result.status == 200) {
          snackRef.current.isOpenSnack({
            snackType: "success",
            snackMessage: "Sub clause linked successfully",
          });
          fetchData();
          handleClose();
          setInputVal({
            name: "",
            description: "",
            subClausenumber: "",
            clauseID: "",
          });
        } else {
          snackRef.current.isOpenSnack({
            snackType: "error",
            snackMessage: result.data.message,
          });
        }
      } else {
        let result = await updateSubClauses({ id: dialogData.id, ...params });
        if (result.status == 200) {
          snackRef.current.isOpenSnack({
            snackType: "success",
            snackMessage: "Sub Clause updated successfully",
          });
          fetchData();
          handleClose();
          setInputVal({
            name: "",
            description: "",
            subClausenumber: "",
            clauseID: "",
          });
        } else {
          snackRef.current.isOpenSnack({
            snackType: "error",
            snackMessage: result.data.message,
          });
        }
      }
    } catch (err) {
      console.error("Error saving company:", err);
    } finally {
      setIsLoading(false);
      // handleClose();
    }
  };

  const snackRef = useRef();

  return (
    <Box>
      <SnackbarAlert ref={snackRef} />
      <BootstrapDialog
        onclosePress={onclosePress}
        onClose={onclosePress}
        open={open}
      >
        <DialogHeader
          title={type === "add" ? "Add Sub Clause" : "Edit Sub Clause"}
          handleClose={onclosePress}
        />
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <CustomDropDown
                selectedValue={inputVal.isoID}
                fieldLabel={"Iso Standard"}
                handleChange={handleChange}
                fieldName={"IsoID"}
              >
                <MenuItem value={""}>Select ISO</MenuItem>
                {isoStandards.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </CustomDropDown>
            </Grid>
            <Grid item xs={12}>
              <CustomDropDown
                selectedValue={inputVal.subClauseID}
                fieldLabel={"Sub Clause Number"}
                handleChange={handleChange}
                fieldName={"subClauseID"}
              >
                <MenuItem value={""}>Select Sub Clause</MenuItem>
                {allClauses.map((item) => {
                  return <MenuItem value={item.id}>{item.title}</MenuItem>;
                })}
              </CustomDropDown>
            </Grid>
            {/* <Grid item xs={12}>
                            <CustomInputLabel label="Sub Clause ID" />
                            <TextInput
                                placeholder="Sub Clause ID"
                                value={inputVal.subClauseID}
                                onChange={(e) => handleChange(e, "subClauseID")}
                            />
                        </Grid> */}
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleSave}
          handleUpdate={handleSave}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </Box>
  );
}

export default AddEditLinktoSubClause;

import { createSlice } from "@reduxjs/toolkit";

export const ISOStandards = createSlice({
  name: "ISO",
  initialState: {
    allStandards: [],
    selectedInd: 0,
    selectedContent: null,
    selectedPage: 0,
    clauseClick: false,
  },
  reducers: {
    setStandards: (state, action) => {
      state.allStandards = action.payload;
    },
    setCurrentClause: (state, { payload }) => {
      state.selectedInd = payload.selectedInd;
      state.selectedContent = payload.selectedContent;
      state.selectedPage = payload.selectedPage;
    },
    setClauseClick: (state, { payload }) => {
      state.clauseClick = payload;
    },
  },
});

export const { setStandards, setCurrentClause, setClauseClick } =
  ISOStandards.actions;

export default ISOStandards.reducer;

import { Image, Page, Text, View } from "@react-pdf/renderer";
import companyLogo from "../../../../assets/images/auditImg.jpg";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";

const PageOne = ({ companyDetails, isoStandards }) => {
  return (
    <Page size={"A4"}>
      <PDFHeaderFooter>
        <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "30px",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                flexDirection: "row",
                borderRight: "1px solid black",
                justifyContent: "center",
                width: "38%",
                padding: "10px 10px 0 10px",
                alignItems: "flex-end",
              }}
            >
              <Image
                src={companyLogo}
                style={{ height: "80%", width: "100%" }}
              />
            </View>
            <View
              style={{
                width: "62%",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingBottom: "30px",
              }}
            >
              <Text
                style={{
                  textTransform: "uppercase",
                  // fontSize: 24,
                  // fontWeight: 700, // Use the correct font weight
                  // fontFamily: "Calibri",
                }}
              >
                AUDIT REPORT
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                borderRight: "1px solid black",
                borderTop: "1px solid black",
                width: "38%",
                padding: "2px 10px 20px 10px",
                backgroundColor: "#e7e6e6",
              }}
            >
              <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
                Client Name:
              </Text>
            </View>
            <View
              style={{
                width: "62%",
                borderTop: "1px solid black",
                padding: "2px 10px 20px 10px",
              }}
            >
              <Text
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {companyDetails.name}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "30px",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                flexDirection: "row",
                borderRight: "1px solid black",
                width: "38%",
                padding: "2px 10px 10px 10px",
                backgroundColor: "#e7e6e6",
              }}
            >
              <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
                Assesment standard(s):
              </Text>
            </View>
            <View
              style={{
                width: "62%",
                padding: "2px 10px 10px 10px",
              }}
            >
              {isoStandards.map((item) => {
                return (
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.name}
                  </Text>
                );
              })}
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                borderRight: "1px solid black",
                width: "38%",
                padding: "2px 10px 10px 10px",
                backgroundColor: "#e7e6e6",
                borderTop: "1px solid black",
              }}
            >
              <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
                Audit Type:
              </Text>
            </View>
            <View
              style={{
                width: "62%",
                borderTop: "1px solid black",
                padding: "2px 10px 20px 10px",
              }}
            >
              <Text
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {companyDetails?.audit_type}
              </Text>
            </View>
          </View>
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default PageOne;

import { endPoints, requestType } from "../../constants/variables";
import Api from "../index";

export const getAllParticipants = (params, token) => {
  return Api(endPoints.reportParticipants, params, requestType.POST, token);
};

export const createParticipants = (params, token) => {
  return Api(endPoints.addReportParticipants, params, requestType.POST, token);
};

export const updateParticipants = (params, token) => {
  return Api(endPoints.updateReportParticipants, params, requestType.POST, token);
};

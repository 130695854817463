import { Box, Grid, Typography } from "@mui/material";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import { getAssignedCompanies } from "../../../../services/assignCompanyToAuditor";
import { getPDFData } from "../../../../services/pdf";
import PdfDocument from "../pdfPages";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import { constants } from "../../../../constants/variables";
import ReactPDF from "@react-pdf/renderer";
import { updateAuditStatus } from "../../../../services/auditors";

const headerData = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Company Name",
  },
  {
    id: "pdfbutton",
    numeric: false,
    disablePadding: true,
    label: "Pdf Button",
    align: "left",
  },
  {
    id: "surveillance",
    numeric: false,
    disablePadding: true,
    label: "Audit Type",
    align: "center",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    align: "center",
  },
];

const diplayRows = [
  "company_name",
  "showPdfButton",
  "surveillanceDrop",
  "showAuditBtn",
];

function AuditCompanies() {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.Authentication);
  const [isLoading, setIsLoading] = useState();
  const [allCompanies, setAllCompanies] = useState([]);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    if (userData) {
      fetctAssignedCompanies(userData.token);
    }
    removeData();
  }, [userData]);

  const removeData = () => {
    localStorage.removeItem("companyDetail");
  };

  const fetctAssignedCompanies = async (token) => {
    try {
      setIsLoading(true);
      const response = await getAssignedCompanies(token);
      if (response.status === 200 || response.status === 201) {
        let data = response.data.data.companyAuditor;
        setAllCompanies(data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handlePressPDF = (row) => {
    let params = {
      company_audit_id: row.id,
    };
    setIsLoading(true);
    getPDFData(params)
      .then(async (response) => {
        if (response.status >= 200 || response.status < 200) {
          setPdfData(response.data.data.auditReport);
          const blob = await pdf(
            <PdfDocument data={response.data.data.auditReport} />
          ).toBlob();
          saveAs(blob, "Audit_Report.pdf");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          constants.snackRef.isOpenSnack({
            snackType: "error",
            snackMessage: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "ERRRRRRRRR");
      });
  };

  const handleRowwwww = (rowww) => {
    localStorage.setItem("companyDetail", JSON.stringify(rowww));
    if (rowww.audit_status == "In-Progress") {
      navigate("/start-auditing", { state: rowww });
    } else {
      navigate("/audit-form", { state: rowww });
    }
  };

  const onChangeDropDownValue = async (value, row) => {
    row["audit_type"] = value;
    let params = {
      audit_type: value,
      audit_status: row.audit_status,
      id: row.id,
    };

    let result = await updateAuditStatus(params);
    fetctAssignedCompanies(userData.token);
    // if (result.status == 200) {
    //   snackRef.current.isOpenSnack({
    //     snackType: "success",
    //     snackMessage: "Audit Completed Successfully",
    //   });
    // } else {
    //   snackRef.current.isOpenSnack({
    //     snackType: "error",
    //     snackMessage: result.data.message,
    //   });
    // }
  };

  return (
    <NavigationDrawer>
      <Grid item xs={12} md={4}></Grid>
      <Loader isloading={isLoading} />
      <Typography variant={"h5"} fontSize={30} fontWeight={700} mb={2}>
        Companies List
      </Typography>
      {/* <PDFViewer showToolbar={true} style={{ width: "100%", height: "100vh" }}>
        <PdfDocument data={pdfData} />
      </PDFViewer> */}
      <DynamicTable
        onChangeDropDownValue={onChangeDropDownValue}
        handlePressPDF={handlePressPDF}
        headerData={headerData}
        bodyRows={allCompanies}
        displayData={diplayRows}
        handlePressBtn={(row) => handleRowwwww(row)}
      />
    </NavigationDrawer>
  );
}

export default AuditCompanies;
